@darkRed: #dc1c2e;
@lightRed: #fbe7e9;

.error-bar-wrapper {
    .flex-center-justify;
    margin: auto auto 20px auto;

    .error-bar {
        .flex-center-justify;
        min-height: 26px;
        padding: 0 8px;
        background-color: @lightRed;
        border: 2px solid @darkRed;
        color: @darkRed;
        line-height: 26px;

        img {
            height: 8px;
            margin-right: 5px;
        }

        .error {
            .circularStdBook;
            font-size: 11px;
            letter-spacing: 0.02em;
        }

        @media @desktop {
            height: 41px;
            padding: 0 12px;
            line-height: 41px;

            img {
                height: 10px;
            }

            .error {
                padding-top: 1px;
                font-size: 14px;
            }
        }
    }
}
