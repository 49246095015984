
#listing_preview_one{
	
	transform-origin:top left;	
	position: absolute;
	color:white;
	
	opacity: 0.85;
    filter: blur(1px);

	.logo{
	    position: absolute;
	    width:92;
	    height:116px;
	    top:101px;
	    left:50%;
	    margin-left:-46px;
	}

	.info{
	    color:white;
	    position: absolute;
	    left:0;
	    width:100%;
	    top:232px;
	    text-align: center;
	}

	.property{
	    .circularStdMedium;
	    font-size:44px;
	    letter-spacing: 1px;
	    height:40px;
	    line-height: 44px;
	}

	.listing_address_1{
	    .circularStdBold;
	    font-size: 72px;
	    text-transform: uppercase;
	    height: 85px;
	    line-height: 99px;
	    letter-spacing: 2px;
	    margin-bottom: 10px;
	}

	.listing_address_2{
	    .circularStdBook;
	    font-size:23px;
	    height:30px;
	    line-height: 35px;
	    letter-spacing: 9px;
	    word-spacing: 6px;
	    text-transform: uppercase;
	}

	.name{
	    .circularStdMedium;
	    font-size: 24px;
	    height: 30px;
	    line-height: 30px;
	    margin-top: 20px;
	    margin-top: 126px;
	    letter-spacing: 1px;
	}

	.agent-info{
	    .circularStdBold;
	    text-transform: uppercase;
	    font-size: 20px;
	    height: 25px;
	    line-height: 28px;
	    margin-top: 3px;
	}

	.h-rule{
	    width:100%;
	    height:3px;
	    background: white;
	}

	.field{
	    display: inline-block;
	    white-space: nowrap;
	}

	.rule{
	    display: inline-block;
	    height:3px;
	    background: white;
	    vertical-align: top;
	    margin-top:26px;
	}
}