
@headerHeight:37px;
@mobileSubHeaderHeight:0px;
@desktopSubHeaderHeight:86px;
@drawerLinkHeight:24px;
@drawerTopPadding:9px;
@drawerBottomPadding:4px;

.header {
    position: fixed;
    z-index:10000;
    top:0px;
    width:100%;
    height: @headerHeight + @mobileSubHeaderHeight;

    @media @laptop {
        height: 97px;
    }

    @media @desktop {
        height: 122px;
    }
    
    .header-content{
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:@headerHeight;
        background: @remaxBlue;
    }
    
    .desktop-sub-header{

        display: none;
        position: absolute;
        top:@headerHeight;
        left:0px;
        width:100%;
        height:@desktopSubHeaderHeight;
        background:white;

        @media @1024{
            display: block;
        }

        @media @laptop {
            height: 60px;
        }

        @media @desktop {
            height: 85px;
        }

        .inner-content{
            width:100%;
            max-width:888px;
            margin:0 auto;
            height:100%;
            display: block;

            &.small {
                max-width: 508px;
            }
        }

        .active-bar {
            position: absolute;
            height: 5px;
            background-color: @remaxRed;
            transition: left 250ms @easeInOutCirc,
                        right 250ms @easeInOutCirc;
        }

        .header-link{
            .no-select;
            display: inline-block;
            width:130px;
            height:100%;
            line-height:@desktopSubHeaderHeight;
            color:@textGreyDarker;
            text-transform:uppercase;
            text-align:center;
            text-decoration:none;
            box-sizing:border-box;
            vertical-align: top;
            margin-left:59px;
            transition:background-color 100ms @easeOutQuad;

            &.first{
                margin-left: 0px;
            }

            @media @laptop {
                .futuraPTBold;
                font-size: 11px;
                letter-spacing: 0.2em;
                line-height: 60px;
            }

            @media @desktop {
                .futuraPTBold;
                line-height: 85px;
                font-size: 16px;
            }
        }
    }

    .mobile-sub-header{
        position: absolute;
        top:@headerHeight;
        left:0px;
        width:100%;
        height:@mobileSubHeaderHeight;
        background:white;
        border-bottom:1px solid #ddd;
            
        @media @1024{
            display: none;
        }

        .active-link{
            .layer;
            .futuraPTBold;
            text-transform:uppercase;
            background:white;
            border-bottom:1px solid #949ca1;
            text-align:center;
            line-height:@mobileSubHeaderHeight;
            font-size:12px;
            letter-spacing:1px;
            color:@remaxBlue;
        }

        .drawer{
            position: absolute;
            width:100%;
            height:(@drawerLinkHeight * 5) + @drawerTopPadding + @drawerBottomPadding;
            top:@mobileSubHeaderHeight;
            left:0px;
            padding-top:@drawerTopPadding;
            padding-bottom:@drawerBottomPadding;
            background:white;
            
            transform:translateY((-@drawerLinkHeight * 5) - @mobileSubHeaderHeight);
            transition:transform 700ms @easeOutQuint;

            &.open{
                transform:translateY(0px);
            }

            .drawer-link{
                display: block;
                width:100%;
                height:@drawerLinkHeight;
                padding:0px;
                margin:0px;
                .futuraPTBold;
                font-size:11px;
                line-height:@drawerLinkHeight;
                text-transform:uppercase;
                letter-spacing:2px;
                text-align:center;
                text-decoration:none;
                color:@textGrey;

                &.active{
                    color:@remaxRed;
                }
            }
        }
    }

    .btn-home{
    
        position: absolute;
        top:6px;
        left:9px;
                  
        color:white;
        background: #266db2;
        width:68px;
        height:25px;
        font-size:12px;
        text-align:center;
        line-height:25px;

        .futuraPTMed;

        .icon-home{
            display: inline-block;
            .retina('../img/icon_home_mobile@2x.png', 11px, 11px, true);
            margin-right: 8px;
            margin-top: 7px;
            vertical-align: top;
        }

        &:hover {
            border: 1px solid rgba(255, 255, 255, 0.4);
        }
    }

    .logo-hustle{
        position: absolute;
        .retina('../img/remax-header-lockup.svg', 164px, 37px);
        .ccnr;
        width:164px;
        height:37px;
        left:50%;
        margin-left:-82px;
        //top:6px;
    }

    .logo-remax{
        position: absolute;
        top: 5px;
        right: 10px;
        .retina('../img/header_remax_logo_mobile@2x.png', 22px, 28px, true);
    }
}
