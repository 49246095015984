
@font-face {
  font-family: 'CircularStd-Bold';
  src: url('/fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-Bold.otf')  format('opentype'),
         url('/fonts/CircularStd-Bold.woff') format('woff'), url('/fonts/CircularStd-Bold.ttf')  format('truetype'), url('/fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'CircularStd-Book';
  src: url('/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-Book.otf')  format('opentype'),
         url('/fonts/CircularStd-Book.woff') format('woff'), url('/fonts/CircularStd-Book.ttf')  format('truetype'), url('/fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'CircularStd-Medium';
  src: url('/fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-Medium.otf')  format('opentype'),
         url('/fonts/CircularStd-Medium.woff') format('woff'), url('/fonts/CircularStd-Medium.ttf')  format('truetype'), url('/fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'SuperClarendon-Italic';
  src: url('/fonts/SuperclarendonRgItalic.ttf?#iefix') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
font-family: "Gotham-Light";
    src: url("/fonts/Gotham-Light.otf") format("opentype");
}
@font-face {
font-family: "Gotham-Book";
    src: url("/fonts/Gotham-Book.otf") format("opentype");
}

@font-face {
font-family: "Gotham-Book-Italic";
    src: url("/fonts/Gotham Book Italic.otf") format("opentype");
}

@font-face {
font-family: "Gotham-Medium";
    src: url("/fonts/Gotham-Medium.otf") format("opentype");
}

.circularStdBook{
    font-family:'CircularStd-Book', sans-serif;
    font-weight:normal;
    font-style:normal;
}

.circularStdBold{
    font-family:'CircularStd-Bold', sans-serif;
    font-weight:bold;
    font-style:normal;
}

.circularStdMedium{
    font-family:'CircularStd-Medium', sans-serif;
    font-weight:normal;
    font-style:normal;
}

.futuraPTMed{
    font-family: futura-pt, sans-serif;
    font-weight: 450;
    font-style: normal;    
}

.futuraPTMedObl{
    font-family: futura-pt, sans-serif;
    font-weight: 450;
    font-style: italic;    
}

.futuraPTHeavy{
    font-family: futura-pt, sans-serif;
    font-weight: 800;
    font-style: normal;    
}

.futuraPTBookObl{
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: italic;    
}

.futuraPTDemi{
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    font-style: normal;    
}

.futuraPTBold{
    font-family: futura-pt-bold, sans-serif;
    font-weight: 700;
    font-style: normal;    
}

.superClarendonItalic {
    font-family: 'SuperClarendon-Italic', serif;
    font-style: italic;
    font-weight: 400;
}
