@import "reset.less";
@import "mixins.less";
@import "transitions.less";
@import "colors.less";
@import "fonts.less";
@import "buttons.less";
@import "components/**/*.less";

html {
    font-variant-ligatures: none;
    -webkit-font-smoothing:antialiased;
}

.module{
    position: fixed;
    transform:translateX(100%);
    width:100%;
    box-sizing: border-box;
    padding-bottom:70px;
    
    &.active{
        transform:translateX(0%);
        position: static;
    }

    &.desktop-mode {
        position: static;
        transform:translateX(0%);
    }
    
    &.blue{
        background: @bgBlue;
        padding-left:20px;
        padding-right:20px;
    }

    &.dark-blue{
        background:@bgDarkBlue;
        padding-left:20px;
        padding-right:20px;
    }

    &.white, &.custom-white {
        background:#fff;
        padding-left:20px;
        padding-right:20px;
    }

    .description{
        .circularStdBook;
        font-size:11px;
        margin-top: -5px;
        margin-bottom: 16px;
        text-align:center;
        width:100%;

        .white&, .custom-white&{
            color:@textGrey;
        }

        .blue&, .dark-blue&{
            color:white;
        }
    }

    .module-header {

        .rule {
            width:100%;
            height:26px;

            .ccnr;

            .white&{
                .retina('../img/module_header_rule_blue@2x.png', 57px, 1px);
            }

            .custom-white&{
                .retina('../img/module_header_rule_dark_blue@2x.png', 57px, 1px);
            }

            .blue&, .dark-blue&{
                .retina('../img/module_header_rule@2x.png', 57px, 1px);
            }
        }
        
        &.mobile {
            padding-top:20px;

            .number{
                width:35px;
                height:35px;
                border-radius:50%;
                .futuraPTBold;
                font-size:19px;
                text-align:center;
                line-height:35px;
                margin:0 auto;

                .blue&{
                    color:@bgBlue;
                    background-color: white;
                }

                .dark-blue&{
                    color:@bgDarkBlue;
                    background-color:white;
                }

                .white&{
                    color:white;
                    background-color:@royalBlue;
                }

                .custom-white&{
                    color:white;
                    background-color:@customBlue;
                }
            }

            .step{
                display: none;
            }

            h2{
                text-align:center;
                color:white;
                .futuraPTBold;
                text-transform:uppercase;
                font-size: 25px;
                letter-spacing: 3px;
                line-height: 24px;
                max-width:310px;
                margin:10px auto 0px auto;

                .blue&, .dark-blue&{
                    color:white;
                }

                .white&{
                    color:@royalBlue;
                }

                .custom-white&{
                    color:@customBlue;
                }
            }
        }

        &.desktop {
            .flex-center-justify;
            flex-direction: column;
            padding: 50px 0 20px 0;

            .number {
                .futuraPTBold;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                font-size: 28px;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;

                .blue&{
                    color:@bgBlue;
                    background-color: white;
                }

                .dark-blue&{
                    color:@bgDarkBlue;
                    background-color:white;
                }

                .white&{
                    color:white;
                    background-color:@royalBlue;
                }

                .custom-white&{
                    color:white;
                    background-color:@customBlue;
                }
            }

            &.desktop-small {
                padding: 25px 0 10px 0;

                .number {
                    width: 25px;
                    height: 25px;
                    font-size: 12px;
                    line-height: 25px;
                }

                .rule {
                    margin-top: 0;
                }
            }

            h2 {
                .futuraPTBold;
                max-width: 470px;
                font-size: 28px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: .16em;

                // keep this here
                @media @desktop {
                    max-width: 706px;
                    font-size: 42px;
                }

                .blue&, .dark-blue&{
                    color:white;
                }

                .white&{
                    color:@royalBlue;
                }

                .custom-white&{
                    color:@customBlue;
                }
            }

            &.half-line-height {
                h2 {
                    line-height: 32px;
                }
            }

            &.wide-title {
                //h2 {
                //    max-width: 629px;
                //}
            }

            .rule {
                margin: 0;
                margin-top: 20px;
            }
        }

        @media @desktop {
            padding: 80px 0 30px 0;

            .number {
                width: 75px;
                height: 75px;
                font-size: 42px;
                line-height: 75px;
                margin: 0 auto 20px auto;
            }

            &.desktop {
                &.desktop-small {
                    padding: 40px 0 15px 0;

                    .number {
                        width: 38px;
                        height: 38px;
                        font-size: 18px;
                        line-height: 38px;
                    }
                }

                &.wide-title {
                    //h2 {
                    //    max-width: 944px;
                    //}
                }

                &.half-line-height {
                    h2 {
                        line-height: 46px;
                    }
                }
            }

            .rule {
                margin: 0;
                margin-top: 30px;

                .white&{
                    .retina('../img/module_header_rule_blue@2x.png', 114px, 2px);
                }

                .custom-white&{
                    .retina('../img/module_header_rule_dark_blue@2x.png', 114px, 2px);
                }

                .blue&{
                    .retina('../img/module_header_rule@2x.png', 114px, 2px);
                }
            }
        }
    }
}
