.app {

    padding-top:@headerHeight + @mobileSubHeaderHeight; 

    @media @768{
        padding-top: @headerHeight; 
    }

    @media @laptop {
        padding-top: 102px;
    }

    @media @desktop{
        padding-top:122px;
    }

    &.no-padding {
        padding-top: 0;
    }
}
