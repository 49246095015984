#recruitment_preview {
    transform-origin:top left;	
	position: absolute;
	color:white;
    position: absolute;
    background: #003471;
    line-height: 1;

    .right-content{
        position: absolute;
        width:830px;
        height:100%;
        top:0px;
        left:852px;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .right-content-container{
        display: flex;
        text-align: center;
        flex-direction: column;
        align-content: center;
        justify-content: flex-end;
        align-items:center;
        margin-bottom:93px;
    }
    
    .right-content.no-image{
        left:528px;
    }
    
    .primary_message{
        display: inline-block;
        line-height: 70px;
        font-family: 'Gotham-Medium', sans-serif;
        font-size: 68px;
        letter-spacing: 1px;
        min-height:70px;
    }

    .secondary_message{
        display: block;
        margin-top: 32px;
        font-size: 44px;
        font-family: 'Gotham-Light', sans-serif;
        letter-spacing: 0px;
        word-spacing: 5px;
        min-height:44px;    
    }

    .additional_info{
        display: block;
        margin-top: 18px;
        font-size: 44px;
        font-family: 'Gotham-Light', sans-serif;
        letter-spacing: 0px;
        word-spacing: 5px;
        min-height:44px;
    }
    
    .image-wrapper{
        position: absolute;;
        width: 510px;
        height: 585px;
        top: 201px;
        left: 292px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #headshot{
        max-width:100%;
        max-height:100%;
        border:12px solid white;
        box-sizing: border-box;
    }
    
    .field{
        white-space: nowrap;
    }

    .inner{
        display: inline-block;
    }

    .brand{
        align-self: center;
    }

    .brand.remax{
        margin-left:43px;
        margin-bottom:3px;
    }

    .brand.remax_collection{
        margin-bottom: 161px;
        margin-top: 126px;
    }

    .brand.remax_commercial{
        margin-bottom: 186px;
        margin-top: 126px;
    }

    .inner{
        display: inline-block;
    }
}