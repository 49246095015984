.BrainhubCarousel {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
.BrainhubCarousel .BrainhubCarousel__trackContainer {
    overflow: hidden; }
.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0; }
.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track.BrainhubCarousel__track--transition {
    -webkit-transition: -webkit-transform;
    transition: -webkit-transform;
    transition: transform;
    transition: transform, -webkit-transform; }

/* arrows */
.BrainhubCarousel__arrows {
    cursor: pointer; }
/* variables */
.BrainhubCarousel__dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0; }
.BrainhubCarousel__dots .BrainhubCarousel__dot {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.5;
    cursor: pointer;
    -webkit-appearance: none; }
.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
    opacity: 1 !important; }
.BrainhubCarousel__dots .BrainhubCarousel__dot:hover {
    opacity: 1; }
.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background: black; }
.BrainhubCarousel__dots .BrainhubCarousel__thumbnail {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.5;
    cursor: pointer; }
.BrainhubCarousel__dots .BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected {
    opacity: 1 !important; }
.BrainhubCarousel__dots .BrainhubCarousel__thumbnail:hover {
    opacity: 1; }

.BrainhubCarousel__thumbnail[type=button] {
    -webkit-appearance: none; }
.BrainhubCarousel__arrows {
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color: #7b59ff; }
.BrainhubCarousel__arrows span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #fff;
    border-width: 3px 3px 0 0;
    padding: 5px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 0; }
.BrainhubCarousel__arrows:hover {
    background-color: #8768ff; }
.BrainhubCarousel__arrows:hover span {
    border-color: #fff;
    margin: 0; }

.BrainhubCarousel__arrowLeft span {
    -webkit-transform: translate(-50%, -50%) rotate(-135deg);
    transform: translate(-50%, -50%) rotate(-135deg);
    margin-left: 2.45px; }

.BrainhubCarousel__arrowRight span {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    margin-left: -2.45px; }
.BrainhubCarouselItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative; }
.BrainhubCarouselItem.BrainhubCarouselItem--clickable {
    cursor: pointer; }
.BrainhubCarouselItem .debug-number {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 2em;
    text-shadow: 0px 0px 9px white; }

.custom-clip-selector {

    .module-buttons {
        .btn {
            width: 160px;
        }
    }

    .select-dialogue {
        .circularStdBold;
        color: @textGrey;
        margin: 0 auto 15px auto;
        width: 100%;
        max-width: 385px;
        font-size: 14px;
        text-align: center;
    }

    .custom-video-wrapper {
        position: relative;
        width: 320px;
        height: 180px;
        margin: 0 auto 20px auto;

        video {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        //4x5 video
        &.vertical{
            width: 144px;
        }

        &.verticalOnly{
            width:101px;
        }

        &.squareOnly{
            width:180px;
        }
    }

    .custom-selection-indicator {
        .no-select;
        display: flex;
        align-items: center;
        position: absolute;
        top: 13px;
        right: 13px;
        cursor: pointer;

        .custom-selection-copy {
            .futuraPTHeavy;
            margin-right: 6px;
            font-size: 14px;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: white;
        }

        .custom-clip-check-wrapper {
            position: relative;
            width: 24px;
            height: 24px;

            .custom-clip-check-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background: none;
                border-radius: 50%;
                border: 3px solid white;
                box-shadow: 0 2px 7px 3px rgba(0, 0, 0, 0.15);
            }

            .custom-clip-check {
                position: absolute;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                .retina('/img/checkmark_white@2x.png', 12px, 8px, false);
                background-position: center center;
                background-repeat: no-repeat;
                opacity: 0;
            }

            &.selected {
                .custom-clip-check-bg {
                    background: @remaxBlue;
                }

                .custom-clip-check {
                    opacity: 1;
                }
            }
        }

        &.small {
            top: 0;
            right: 0;
            transform: scale(0.6);

            .custom-selection-copy {
                display: none;
            }
        }
    }

    .kind-of-agent {
        .circularStdBold;
        font-size: 15px;
        color: @textGreyDarker;
        text-align: center;
        margin: 0 auto;
        width: 225px;
    }

    .easy-dialogue {
        width: 90%;
        min-height: 38px;
        .circularStdBold;
        color: @textGrey;
        font-size: 13px;
        text-align: center;
        margin: 0 auto 15px auto;

        a{
            color:@textGrey;
        }
    }

    .clip-legal {
        width: 70%;
        min-height: 20px;
        .circularStdMedium;
        color: @textGrey;
        font-size: 10px;
        text-align: center;
        margin: 0 auto 15px auto;

        a{
            color:@textGrey;
        }
    }

    .aspect-ratios{

        .aspect-selector{
            width: 90%;
            .circularStdBold;
            color: @textGrey;
            font-size: 12px;
            text-align: center;
            margin: 0 auto 5px auto;
        }

        margin-bottom:15px;
    }
    

    .carousel-wrapper {
        margin: 0 auto 23px auto;
    }

    .arrow-wrapper {
        position: relative;
        width: 15px;
        height: 17.5px;
        cursor: pointer;
    }

    .arrow {
        .layer;
        z-index: 3;
        transition: transform 200ms @easeOutQuad;
    }

    .left-arrow {
        .arrow;
        .retina('../img/carousel_arrow_left@2x.png', 10px, 17.5px, false);
        background-repeat: no-repeat;
        background-position: right;

        &:hover {
            transform: translateX(-5px);
        }
    }

    .right-arrow {
        .arrow;
        .retina('../img/carousel_arrow_right@2x.png', 10px, 17.5px);
        background-repeat: no-repeat;
        background-position: left;

        &:hover {
            transform: translateX(5px);
        }
    }

    .custom-clip {
        width: 135px;
        height: 77px;
        position: relative;
        box-sizing: border-box;
        border: 4px solid transparent;

        &.selected {
            border: 4px solid @remaxBlue;
        }

        .new-banner {
            position: absolute;
            top: 5px;
            left: -7px;
            width: 42px;
            height: 24px;
            .retina('../img/new_custom_clip@2x.png', 42px, 24px);
            background-repeat: no-repeat;
            background-position: left;
        }

        .can-banner{
            position: absolute;
            top: 5px;
            left: -7px;
            width: 70px;
            height: 24px;
            .retina('../img/can_only.png', 70px, 24px);
            background-repeat: no-repeat;
            background-position: left;
        }

        .us-banner{
            position: absolute;
            top: 5px;
            left: -7px;
            width: 70px;
            height: 24px;
            .retina('../img/us_only.png', 70px, 24px);
            background-repeat: no-repeat;
            background-position: left;
        }
    }

    .btn-select-all {
        border: 2px solid @remaxBlue;
        color: @remaxBlue;
        background: white;
        margin-left: calc(50% - 90px);
        margin-bottom: 60px;
        width: 180px;
        box-sizing: content-box;
        font-size: 14px;
        height: 29px;
        line-height: 29px;
        padding: 0;
        transition:all 100ms @easeOutQuad;

        &:hover {
            background: @remaxBlue;
            color: white;
        }
    }

    .create-dialogue {
        width: 100%;
        .circularStdBold;
        color: @textGrey;
        font-size: 13px;
        text-align: center;
        margin: 0 auto 33px auto;
    }

    .btn-create-custom-clips{
        .hover;
        width: 211px;
        height: 40px;
        margin-left: calc(50% - 105.5px);
        line-height: 40px;
        font-size: 16px;
        box-sizing: content-box;
        padding: 0;

        &:hover {
            padding: 0;
            box-sizing: content-box;
        }
    }

    @media @laptop {
        .select-dialogue {
            .circularStdBold;
            color: @textGrey;
            margin: 0 auto 43px auto;
            width: 470px;
            max-width: 470px;
            font-size: 15px;
            text-align: center;
        }

        .custom-video-wrapper {
            position: relative;
            width: 470px;
            height: 264px;
            margin: 0 auto 10px auto;

            //4x5 video
            &.vertical{
                width:212px;
            }

            //9x16 video
            &.verticalOnly{
                width:149px;
            }

            //1x1 video
            &.squareOnly{
                width:264px;
            }
        }

        .custom-selection-indicator {
            .no-select;
            display: flex;
            align-items: center;
            position: absolute;
            top: 13px;
            right: 13px;
            cursor: pointer;

            .custom-selection-copy {
                .futuraPTHeavy;
                margin-right: 7px;
                font-size: 13px;
                letter-spacing: 3px;
                text-transform: uppercase;
                color: white;
            }

            .custom-clip-check-wrapper {
                position: relative;
                width: 26px;
                height: 26px;

                .custom-clip-check-bg {
                    border: 3px solid white;
                }

                .custom-clip-check {
                    .retina('/img/checkmark_white@2x.png', 13px, 9px, false);
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }

            &.small {
                top: 0;
                right: 0;
                transform: scale(0.6);

                .custom-selection-copy {
                    display: none;
                }
            }
        }

        .kind-of-agent {
            .circularStdBold;
            font-size: 20px;
            color: @textGreyDarker;
            text-align: center;
            margin: 0 auto;
            width:100%;
        }

        .easy-dialogue {
            width: 550px;
            min-height: 38px;
            .circularStdBold;
            color: @textGrey;
            font-size: 13px;
            text-align: center;
            margin: 0 auto 20px auto;
        }

        .clip-legal {
            width: 400px;
            min-height: 20px;
            .circularStdMedium;
            color: @textGrey;
            font-size:10px;
            text-align: center;
            margin: 0 auto 20px auto;
        }

        .aspect-ratios{
            margin-top:-20px;
            margin-bottom:30px;
            .aspect-selector {
                width: 550px;
            }
        }
        

        .carousel-wrapper {
            width: 837px;
            margin: 0 auto 23px auto;
        }

        .custom-clip {
            width: 135px;
            height: 77px;
            position: relative;

            &.selected {
                border: 4px solid @remaxBlue;
            }
        }

        .btn-select-all {
            border: 2px solid @remaxBlue;
            color: @remaxBlue;
            background: white;
            margin-left: calc(50% - 90px);
            margin-bottom: 110px;
            width: 180px;
            box-sizing: content-box;
            font-size: 15px;
            height: 29px;
            line-height: 29px;
            padding: 0;
        }

        .create-dialogue {
            width: 400px;
            .circularStdBold;
            color: @textGrey;
            font-size: 13px;
            text-align: center;
            margin: 0 auto 33px auto;
        }

        .btn-create-custom-clips{
            width: 211px;
            height: 40px;
            margin-left: calc(50% - 105.5px);
            line-height: 40px;
            font-size: 16px;
            box-sizing: content-box;
            padding: 0;
        }
    }

    @media @desktop {
        .select-dialogue {
            margin: 0 auto 65px auto;
            width: 706px;
            max-width: 706px;
            font-size: 23px;
        }

        .custom-video-wrapper {
            width: 706px;
            height: 397px;
            margin: 0 auto 30px auto;

            //4x5 video
            &.vertical{
                width:317px;
            }

            //9x16 video
            &.verticalOnly{
                width:223px;
            }

            //1x1 video
            &.squareOnly{
                width:397px;
            }
        }

        .custom-selection-indicator {
            top: 20px;
            right: 20px;

            .custom-selection-copy {
                margin-right: 12px;
                font-size: 20px;
                letter-spacing: 5px;
            }

            .custom-clip-check-wrapper {
                width: 39px;
                height: 39px;

                .custom-clip-check-bg {
                    border: 5px solid white;
                }

                .custom-clip-check {
                    .retina('/img/checkmark_white@2x.png', 20px, 14px, false);
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }

        .kind-of-agent {
            font-size: 30px;
            width:100%;
        }

        .easy-dialogue {
            width: 800px;
            font-size: 20px;
            min-height: 60px;
            margin: 0 auto 30px auto;
        }

        .clip-legal {
            width: 600px;
            font-size:16px;
            min-height: 40px;
            margin: 0 auto 30px auto;
        }

        .aspect-ratios{
            margin-top:-20px;
            margin-bottom:40px;
            .aspect-selector {
                width: 550px;
                font-size:15px;
            }
        }

        .carousel-wrapper {
            margin: 0 auto 35px auto;
        }

        .arrow-wrapper {
            position: relative;
            width: 23px;
            height: 26.5px;
            cursor: pointer;
        }

        .left-arrow {
            .retina('../img/carousel_arrow_left@2x.png', 15px, 26.5px, false);
            background-repeat: no-repeat;
            background-position: right;

            &:hover {
                transform: translateX(-8px);
            }
        }

        .right-arrow {
            .retina('../img/carousel_arrow_right@2x.png', 15px, 26.5px);
            background-repeat: no-repeat;
            background-position: left;

            &:hover {
                transform: translateX(8px);
            }
        }

        .custom-clip {
            width: 203px;
            height: 116px;
            border: 6px solid transparent;

            &.selected {
                border: 6px solid @remaxBlue;
            }

            .new-banner {
                top: 7px;
                left: -10px;
                width: 63px;
                height: 36px;
                .retina('../img/new_custom_clip@2x.png', 63px, 36px);
                background-repeat: no-repeat;
                background-position: left;
            }

            .can-banner{
                position: absolute;
                top: 7px;
                left: -11px;
                width: 106px;
                height: 36px;
                .retina('../img/can_only.png', 106px, 36px);
                background-repeat: no-repeat;
                background-position: left;
            }

            .us-banner{
                position: absolute;
                top: 7px;
                left: -11px;
                width: 106px;
                height: 36px;
                .retina('../img/us_only.png', 106px, 36px);
                background-repeat: no-repeat;
                background-position: left;
            }
        }

        .btn-select-all {
            width: 270px;
            margin-left: calc(50% - 135px);
            margin-bottom: 165px;
            border: 3px solid @remaxBlue;
            font-size: 22px;
            height: 44px;
            line-height: 44px;
        }

        .create-dialogue {
            width: 600px;
            font-size: 20px;
            margin: 0 auto 50px auto;
        }

        .btn-create-custom-clips{
            width: 317px;
            height: 60px;
            margin-left: calc(50% - 158.5px);
            line-height: 60px;
            font-size: 24px;
        }
    }
}
