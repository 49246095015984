
#agent_preview{
	
	transform-origin:top left;	
	position: absolute;
	color:white;
    background:black;

	&.remax{
        background: #003471;
    }

    &.remax_collection{
        background:#1f355e;
    }

    &.remax_commercial{
        background:white;
        color:#003471;
    }

    &.remax_commercial .legal{
        color:#19305d;
        opacity: 0.5;
    }

    &.remax_commercial #headshot{
        border:12px solid #19305d;
    }

    &.remax_commercial .h-rule{
        background: #19305d;
    }
    
    .right-content{
        position: absolute;
        width:864px;
        height:540px;
        top:176px;
        left:874px;
        text-align:center;
        .circularStdBook;
    }

    .right-content.no-image{
        left:528px;
        top:156px;
    }

    .right-content.no-image .name{
        margin-top: 241px;
        font-size:114px;
    }

    .right-content .logo{
    	position:absolute;
    	top:0px;
		left:50%;

    	&.remax{
			
			&.large{
				width:160px;
				height:200px;
				margin-left:-80px;
			}
    	}

    	&.remax_commercial{
			
			width:230px;
			height:69px;
			margin-left:-115px;

			&.large{
				top:93px;
				width:304px;
				height:91px;
				margin-left:-152px;
			}
    	}

    	&.remax_collection{
			
			width:278px;
			height:94px;
			margin-left:-139px;

    		&.large{
				top:79px;
    		}
    	}
    }

    .h-rule{
        width:100%;
        height:3px;
        margin-top: 32px;
        background: white;   
    }

    .name{
        height:100px;
        margin-top: 142px;
        line-height: 100px;
        .circularStdMedium;
        font-size: 100px;
        letter-spacing: 1px;
    }

    .info{
        height:30px;
        font-size: 28px;
        line-height: 30px;
        margin-top: 20px;
        letter-spacing: 2px;
        word-spacing: 4px;
    }

    .contact{
        .circularStdBold;
        font-size: 40px;
        height:45px;
        line-height: 45px;
        letter-spacing: 3px;
        word-spacing: 3px;
        margin-top: 34px;
        margin-bottom: 6px;
    }

    .office-info{
        height:85px;
        .circularStdBook;
        font-size: 28px;
        letter-spacing: 2px;
        margin-top: 29px;
        line-height: 42px;
        word-spacing: 2px;
    }

    .personal {
        .superClarendonItalic;
        height: 85px;
        font-size: 30px;
        line-height: 35px;
        word-spacing: 3px;
        margin-top: 35px;
    }

    .legal{
        color:white;
        opacity: 0.6;
        position: absolute;
        width:864px;
        left:874px;
        bottom: 195px;
        .circularStdMedium;
        font-size:17px;
        text-align: center;
    }

    .legal.no-image{
        left:528px;  
        bottom:91px;
    }

    .image-wrapper{
        position: absolute;
        width:600px;
        height:685px;
        top:172px;
        left:172px;
        
        display: flex;
        justify-content: center;
        align-items: center;

        &.no-image{
        	display: none;
        }
    }

    .logo-wrapper{
        position: absolute;
        bottom:-100px;
        left:50%;
        margin-left:-100px;
        width:200px;
        height:200px;
        background: white;
        border-radius: 50%;
        overflow:hidden;
    }
    
    .logo-wrapper .logo{
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
    }

    #headshot{
        max-width:100%;
        max-height:100%;
        box-sizing: border-box;
        border:12px solid white;
        &.no-border {
            border: 0;
        }
    }
    
    .field{
        display: inline-block;
        white-space: nowrap;
    }  

    .hidden {
        opacity: 0;
    }

    &.iam {
        background: url("https://d1haqt8qautnl4.cloudfront.net/iam_img/prominence-bg.jpg") 100% 100% no-repeat;

        .right-content {
            top: 196px;

            &.no-image {
                top: 156px;
            }
        }
    }

    &.prominence {
        .right-content {
            width: 1215px;
            height: 540px;
            left: 352px;
            top: 359px;

            &.no-image {
                top: 359px;
            }
        }

        .h-rule {
            margin-top: 32px;
        }

        .personal {
            height: auto;
            font-size: 32px;
            margin-top: 24px;
            letter-spacing: 1px;
        }

        .prominence-name-title {
            .circularStdBold;
            height: 25px;
            font-size: 50px;
            line-height: 30px;
            letter-spacing: 4px;
            word-spacing: 4px;
            margin-top: 30px;

            &.no-message {
                margin-top: 14px;
            }
        }

        .contact {
            .circularStdBold;
            font-size: 42px;
            height: 45px;
            line-height: 45px;
            letter-spacing: 4px;
            word-spacing: 4px;
            margin-top: 20px;
            margin-bottom: 0;
        }

        .office-info {
            .circularStdBook;
            height: 85px;
            font-size: 66px;
            letter-spacing: 3px;
            margin-top: 44px;
            line-height: 84px;
            word-spacing: 5px;
        }

        .logo-wrapper {
            position: absolute;
            bottom:-100px;
            left:50%;
            margin-left:-100px;
            width:200px;
            height:200px;
            background: white;
            border-radius: 50%;
        }

        .logo-wrapper .logo{
            position: absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
        }

        .legal {
            width:864px;
            left:528px;
            //bottom:100px;
        }

        .right-content .logo{
            &.remax{
                &.large{
                    top: -210px;
                }
            }

            &.remax_commercial{
                &.large{
                    top: -160px;
                }
            }

            &.remax_collection{
                &.large{
                    top:-160px;
                }
            }
        }

        &.headshot {
            .right-content {
                width:1055px;
                height:575px;
                top:375px;
                left:680px;

                .logo {
                    &.remax {
                        top: -195px;
                        left: 50%;
                        margin-left: -125px;
                    }

                    &.remax_commercial {
                        top: -140px;
                    }

                    &.remax_collection {
                        top: -128px;
                    }
                }
            }

            .image-wrapper{
                width:434px;
                height:496px;
                top:279px;
                left:182px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .legal {
                bottom: 100px;
                width:864px;
                left: calc(50% - 432px);
            }
        }
    }
}
