
.placeholder{
    display: inline-block;
    position: relative;
    width:155px;
    height:88px;
    margin:2px;
}

.clip{
    .no-select;
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 155px;
    height: 87.1875px;
    margin: 2px;
    overflow: hidden;
    transition: all 300ms @easeInOutCirc;
    transform: translateZ(0);
    cursor: pointer;

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    
    &:hover {
        
        .selection-indicator{
            background-color:rgba(0, 84, 164, 0.4);

            &.selected{
                background: @royalBlue;
            }
        }
    }

    video{
        object-fit: fill;
        .layer;
        display: none;

        &.show{
            display: block;
        }
    }

    img{
        object-fit: fill;
        .layer;
    }
    
    .experience-wrapper {
        .flex-center-justify;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 24px;
        background-color: @royalBlue;

        input {
            .circularStdBold;
            max-width: 70px;
            height: 16px;
            margin-right: 10px;
            padding: 0 10px;
            border: none;
            border-radius: 0px;
            box-sizing: border-box;
            -webkit-appearance: none;
            background: white;
            color: @remaxBlue;
            font-size: 11px;
            line-height: 16px;
        }

        .years {
            .futuraPTBold;
            color:white;
            font-size: 11px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
        }
    }

    &.listing {
        .clip-title {
            .circularStdBold;
            position: absolute;
            top: 50%;
            width: 100%;
            height: 20px;
            margin-top: -10px;
            color: white;
            font-size: 12px;
            box-sizing: border-box;
            border: 1px solid transparent;
            display: block;
        }

        &.second-clip-disabled {
            opacity: 1;

            .clip-title {
                opacity: 0;
            }
        }

        &.fade-second {
            opacity: 0.3;
        }

        .disabled-second-clip-layer {
            .layer;
            .flex-center-justify;
            flex-direction: column;
            opacity: 1;
            background: rgba(0, 113, 185, 0.6);

            .title {
                .circularStdBold;
                font-size: 12px;
                color: white;
            }

            .title-guide {
                .circularStdBold;
                font-size: 9px;
                color: white;
            }
        }

        .editable-clip-title {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 20px;
            width: 140px;
            margin-top: -10px;
            margin-left: -70px;
            border: 1px solid white;
            box-sizing: border-box;
            background: rgba(225, 225, 225, 0.3);

            input {
                .circularStdBold;
                display: block;
                background: none;
                border: none;
                width: 100%;
                height: 100%;
                line-height: 20px;
                color: white;
                font-size: 12px;
                text-align: center;
                padding: 0;
            }
        }

        .btn-customize-listing-clip {
            .futuraPTBold;
            position: absolute;
            top: 7px;
            right: 37px;
            font-size: 8px;
            background: white;
            border: 2px solid @bgBlue;
            color: @remaxBlue;
            padding: 2px;
            letter-spacing: 1px;
            text-transform: uppercase;
            opacity: 0.8;
            transition: opacity 200ms @easeOutQuad;

            &.done {
                background: @bgBlue;
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    @media @laptop {

        width: 225px;
        height: 126.5625px;
        margin: 0 19px 0 0;

        &:last-child {
            margin: 0;
        }

        .experience-wrapper {
            height: 30px;

            input {
                height: 18px;
                font-size: 14px;
                line-height: 18px;
            }

            .years {
                font-size: 14px;
            }
        }

        &.listing {
            .clip-title {
                height: 30px;
                line-height: 30px;
                margin-top: -15px;
                font-size: 16px;
            }

            .disabled-second-clip-layer {
                .title {
                    font-size: 16px;
                }

                .title-guide {
                    font-size: 11px;
                }
            }

            .editable-clip-title {
                height: 30px;
                width: 169px;
                margin-top: -15px;
                margin-left: -84.5px;

                input {
                    line-height: 30px;
                    font-size: 16px;
                }
            }

            .btn-customize-listing-clip {
                top: 7px;
                right: 37px;
                font-size: 10px;
            }
        }
    }

    @media @desktop {
        width: 338px;
        height: 190px;
        margin: 0 28px 0 0;

        .experience-wrapper {
            height: 40px;

            input {
                height: 26px;
                font-size: 22px;
                line-height: 26px;
            }

            .years {
                font-size: 22px;
            }
        }

        &.listing {
            .clip-title {
                height: 42px;
                line-height: 42px;
                margin-top: -21px;
                font-size: 24px;
            }

            .disabled-second-clip-layer {
                .title {
                    font-size: 24px;
                }

                .title-guide {
                    font-size: 13px;
                }
            }

            .editable-clip-title {
                height: 42px;
                width: 254px;
                margin-top: -21px;
                margin-left: -127px;

                input {
                    line-height: 42px;
                    font-size: 24px;
                }
            }

            .disabled-second-clip-layer {
                span {
                    font-size: 24px;
                    line-height: 28px;
                }
            }

            .btn-customize-listing-clip {
                top: 17.5px;
                right: 64px;
                font-size: 15px;
                padding: 3px;
            }
        }
    }
}
