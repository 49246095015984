
@previewFooterHeight:21px;

.preview-wrapper{

    background:#ddd;
    width:100%;
    height:10px;
    position: relative;
    margin-top:3px;
    margin-bottom:@previewFooterHeight + 7px;
    overflow:hidden;

    &.listing-one{
        background:url('../../img/listing_preview_one_bg.jpg') center center no-repeat;
        background-size:100% 100%;
    }

    &.listing-two{
        background:url('../../img/listing_preview_two_bg.jpg') center center no-repeat;
        background-size:100% 100%;
    }
}

.preview{
    background:#ddd;
    width:100%;
    height:10px;
    position: relative;
    margin-top:3px;
    margin-bottom:@previewFooterHeight + 7px;
    overflow:hidden;

    .preview-footer{
        width:100%;
        background:@royalBlue;
        height:@previewFooterHeight;
        position: absolute;
        bottom:-@previewFooterHeight;
        color:white;
        text-align:center;
        .circularStdBold;
        font-size:11px;
        line-height:21px;
    }
    
    .brand-logo{
        position: absolute;
        transform-origin: top left;

        img{
            position: absolute;
            top:0px;
            left:0px;
            opacity: 0.9;
        }
    }

    .card-preview-info{
            
        width:484px;
        height:474px;
        position: absolute;
        top:0px;
        left:0px;
        transform-origin:top left;
        color:rgba(65,65,65,1);

        .name{
            .futuraPTHeavy;
            text-transform: uppercase;
            margin-top: 120px;
            font-size: 42px;
            letter-spacing: 5px;
            word-spacing: 0px;
            height:63px;
            display: inline-block;
            white-space: nowrap;
        }

        .italic{
            .superClarendonItalic;
            font-size: 23px;
            height:34px;
            display: inline-block;
            white-space: nowrap;
        }

        .rule{
            width:100%;
            height:2px;
            border-top:2px solid #414042;
        }

        .rule-top{
            margin-top: 22px; 
            margin-bottom:20px;  
        }

        .rule-bottom{
            margin-top: 24px;
            margin-bottom: 8px;
        }

        .field{
            .futuraPTHeavy;
            font-size: 22px;
            letter-spacing: 2px;
            height:33px;
            display: inline-block;
            white-space: nowrap;
        }

        .small{
            .circularStdBook;
            font-size: 17px;
            line-height: 16px;
            height:19px;
            display: inline-block;
            white-space: nowrap;
        }

        .title{
            margin-top:1px;
        }

        .email, .web{
            margin-top:5px;
        }

        .office-address{
            margin-top:4px;
        }
    }
    
    .card-legal{
        position: absolute;
        width:1920px;
        height:50px;
        bottom:10px;
        left:0px;
        .superClarendonItalic;
        color:white;
        font-size: 17px;
        opacity: 0.7;
        font-weight:bold;
        text-align: center;
        transform-origin: bottom left;
    }

    @media @laptop {
        width: 470px;
        height: 265px;

        .preview-footer {
            .futuraPTDemi;
            height: 30px;
            font-size: 14px;
            letter-spacing: 0.05em;
            line-height: 30px;
        }
    }

    @media @desktop {
        width: 706px;
        height: 400px;

        .preview-footer {
            height: 45px;
            font-size: 22px;
            line-height: 45px;
        }
    }
}