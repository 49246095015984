
.welcome {

    background:#fff;
    text-align:center;
    
    //BUILD ANIMATION
    &.show{

        .hero{
            .bg{
                opacity: 1;
                transform:scale(1);
            }
            .content-wrapper{
                
                .copy{
                    .create{
                        opacity:1;
                        transform:translateY(0px);
                    }
                    .commercial{
                        opacity:1;
                        transform:translateY(0px);
                    }
                    .rules{
                        opacity:1;
                    }
                }
            }
        }

        .info{
            opacity:1;
        }

        #btn-get-started-mobile{
            opacity: 1;
            transform:translateY(0px);
        }
    }

    &.custom.canada{
        .hero{
            .bg{
                background-image: url('/img/welcome_hero_mobile_canada.jpg');
            }

            .content{
                .banner-title{
                    color:white;
                    max-width: 300px;
                    line-height: 24px;
                }
            }

            .wide-rule-one{

            }

            .wide-rule-two{
                
            }
        }
    }

    .hero {
        width:100%;
        height:160px;
        position: relative;
        overflow:hidden;

        .bg{
            .layer;
            background-image: url('/img/welcome_hero_mobile.jpg');
            background-size: cover;

            //BUILD ANIMATION
            opacity: 0;
            transform:scale(1.05);
            transform-origin:center center;
            transition:opacity 300ms @easeOutQuad, transform 1200ms @easeOutQuad;
        }

        .content{
            .layer;
            display: table;
        }

        .content-wrapper {
            display: table-cell;
            vertical-align: middle;

            .copy{
        
                color:white;

                .bd{
                    .futuraPTBold;
                    text-transform:uppercase;
                    font-size: 29px;
                    letter-spacing: 4px;
                }
                
                .rules{
                    width:100%;
                    display:block;
                    height:24px;
                    .retina('/img/hero_rules@2x.png', 250px, 2px);
                    .ccnr;

                    .it{
                        .superClarendonItalic;
                        display: inline-block;
                    }
                }

                //BUILD ANIMATION
                // .create{
                //     opacity: 0;
                //     transform:translateY(-10px);
                //     transition:all 900ms @easeOutQuint 400ms;
                // }

                // .commercial{
                //     opacity: 0;
                //     transform:translateY(10px);
                //     transition:all 700ms @easeOutQuad 400ms;
                // }   

                // .rules{
                //     opacity: 0;
                //     transition:all 900ms @easeOutQuint 400ms;
                // }

            }
        }

    }

    .info{

        //BUILD ANIMATION
        opacity: 0;
        transition:opacity 400ms @easeOutQuad 800ms;

        padding-left:20px;
        padding-right:20px;
        box-sizing: border-box;

        h1{
            .circularStdBold;
            color:@textGrey;
            margin: 45px auto 0 auto;
            width: 100%;
            max-width: 220px;
            font-size: 17px;
            line-height: 20px;
        }

        p{
            .circularStdBook;
            color:@textGrey;
            font-size: 14px;
            width: 100%;
            margin: 15px auto 0 auto;
            max-width: 350px;
            letter-spacing: 0px;
            line-height: 16px;
        }

        .get-started-wrapper {
            display: none;
        }
    }

    #preview{
        width:320px;
        height:180px;
        background-color: #dadada;
        margin: 25px auto 0px auto;
    }

    #btn-get-started-mobile{
        margin-top: 25px;
        line-height: 17px;

        .icon-next {
            margin-left: 3px;
            margin-top: 3.66px;
            vertical-align: top;
        }

        //BUILD ANIMATION
        opacity: 0;
        transform:translateY(10px);
        transition:all 400ms @easeOutQuad 1000ms;
    }

    // Add styles for listing welcome page here
    &.listing {
        height: calc(100vh - 180px);

        .info {
            h1 {
                color: @remaxBlue;
                max-width: 180px;
            }

            p {
                .circularStdBold;
            }
        }
    }
    &.recruitment{
        .info{
            h1{
                color:@customBlue !important;
            }
            p {
                .circularStdBold;
            }
        }

        .get-started-wrapper {
            span {
                color: @customBlue;
            }
        }
    }

    // Add styles for custom welcome page here
    &.custom, &.iam {
        .content {
            .flex-center-justify;
            flex-direction: column;
        }

        .hero {
            .bg {
                background-image: url('/img/custom_hero_mobile.jpg');
                background-size: cover;
            }

            .wide-rule-one, .wide-rule-two {
                width: 350px;
                border-color:white;
                border-style: solid;
                border-width: 2px;
            }

            .wide-rule-one {
                margin-bottom: 28px;
            }

            .wide-rule-two {
                margin-top: 28px;
            }

            .banner-title {
                .circularStdBold;
                font-size: 24px;
                letter-spacing: 1px;
                color: white;
            }
        }

        .info {
            h1 {
                color: @customBlue;
            }

            p {
                .circularStdBold;
                color: @textGrey;
            }
        }
    }

    &.iam {
        .info {
            h1 {
                font-size: 21px;
                color: @royalBlue;
            }

            p {
                font-size: 13px;
            }
        }

        video {
            background: #ccc;
            width: 320px;
            height: 180px;
            margin: 15px auto 0 auto;
        }

        .video-caption {
            .circularStdBold;
            color: @textGrey;
            font-size: 11px;
            width: 100%;
            margin: 10px auto 0 auto;
            max-width: 350px;
            line-height: 16px;
        }
    }

    @media @768x2 {
        &.listing, &.recruitment {
            height: auto;
        }
    }

    @media @laptop {
        .hero {
            
            height: 400px;

            .bg{
                background-image: url('/img/welcome_hero_laptop.jpg');
            }

            .content{
                .flex-center-justify;
            }

            .content-wrapper {
                width: 960px;
                display: flex;
                justify-content: flex-end;

                .copy {
                    .flex-center-justify;
                    flex-direction: column;
                    width: 470px;
                    letter-spacing: 0.16em;

                    .bd {
                        font-size: 50px;
                    }

                    .rules {
                        height: 32px;
                        .retina('/img/hero_rules@2x.png', 437.5px, 2px);

                        .it {
                            line-height: 32px;
                            font-size: 32px;
                            font-style: italic;
                        }
                    }
                }
            }
        }

        .info {
            .flex-center-justify;
            padding-top: 50px;

            .col {

                &:first-child {
                    width: 470px;
                    margin-right: 20px;
                }
            }

            h1 {
                font-size: 23px;
                max-width: 350px;
                line-height: 26.5px;
                margin: 0 auto;
            }

            p {
                .circularStdBook;
                font-size: 14px;
                max-width: 370px;
                line-height: 20px;
                letter-spacing: 0.025em;
            }

            .get-started-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 20px auto 0 auto;
                cursor: pointer;

                span {
                    .futuraPTBold;
                    color: #b2292e;
                    font-size: 11px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;

                }

                .arrow {
                    width: 12px;
                    height: 12px;
                    .retina('/img/red-arrow@2x.png', 12px, 12px);
                    transition:transform 400ms @easeOutBack;
                }

                &:hover{
                    .arrow{
                        transform:translateY(7px);
                    }
                }
            }

            #preview {
                margin: 0;
                width: 470px;
                height: 265px;
            }
        }

        #btn-get-started-mobile {
            display: none;
        }

        // LAPTOP LISTING STYLES
        &.listing, &.recruitment {
            .info {
                .col {
                    width: 385px;
                    margin-right: 0;
                }

                h1 {
                    font-size: 39px;
                    line-height: 39px;
                    color: @remaxBlue;
                    max-width: 385px;
                }

                p {
                    font-size: 15px;
                    max-width: 385px;
                    line-height: 23px;
                    margin-top: 27px;
                }
            }

            .get-started-wrapper {
                margin-top: 67px;

                span {
                    .circularStdBold;
                    color: @remaxBlue;
                    font-size: 17px;
                    text-transform: capitalize;
                    letter-spacing: initial;
                }

                .arrow {
                    margin-top: 10px;
                    width: 18px;
                    height: 10px;
                    .retina('/img/blue-arrow@2x.png', 18px, 10px);

                    &.recruitment{
                        .retina('/img/blue-arrow-custom@2x.png', 18px, 10px);                        
                    }
                }
            }
        }

        &.recruitment{
            .info{
                h1{
                    color:@customBlue;
                }
            }
            .get-started-wrapper {
                span {
                    color: @customBlue;
                }
            }
        }

        // LAPTOP CUSTOM STYLES
        &.custom, &.iam {
            .hero {
                height: 248px;

                .bg{
                    background-image: url('/img/custom_hero_laptop.jpg');
                }

                .content {
                    .wide-rule-one, .wide-rule-two {
                        width: 696px;
                        border-width:3px;
                    }

                    .wide-rule-one {
                        margin-bottom: 40px;
                    }

                    .wide-rule-two {
                        margin-top: 40px;
                    }

                    .banner-title {
                        font-size: 50px;
                    }
                }
            }

            .info {
                .col {
                    width: 471px;
                }

                h1 {
                    max-width: 471px;
                }

                p {
                    max-width: 385px;
                }
            }
        }

        &.custom.canada {

            .hero{

                .bg{
                    background-image: url('/img/welcome_hero_laptop_canada.jpg');
                }

                .content{
                    .banner-title{
                        max-width: 600px;
                        line-height: 45px;
                    }
                }
            }

            .info {

                .col {
                    width: 500px;
                }

                h1 {
                    max-width: 500px;
                }
            }
        }

        &.iam {
            .info {
                padding-top: 100px;
                flex-direction: column;

                h1 {
                    font-size: 43px;
                }

                p {
                    max-width: 395px;
                    margin-top: 27px;
                    font-size: 15px;
                }
            }

            video {
                margin-top: 27px;
                width: 470px;
                height: 265px;
            }

            .video-caption {
                margin-top: 14px;
                font-size: 12px;
                max-width: 370px;
                letter-spacing: 0.025em;
            }
        }
    }

    @media @desktop {
        .hero {
            height: 597px;
            
            .bg{
                background-image: url('../../img/welcome_hero_desktop.jpg');
            }

            .content-wrapper {
                width: 1430px;

                .copy {
                    width: 706px;

                    .bd {
                        font-size: 74px;
                    }

                    .rules {
                        height: 48px;
                        .retina('/img/hero_rules@2x.png', 656px, 2px);

                        .it {
                            line-height: 48px;
                            font-size: 48px;
                        }
                    }
                }
            }
        }

        .info {
            padding-top: 80px;

            .col {
                &:first-child {
                    width: 706px;
                    margin-right: 28px;
                }
            }

            h1 {
                font-size: 34px;
                max-width: 523px;
                line-height: 40px;
            }

            p {
                font-size: 22px;
                max-width: 579px;
                line-height: 32px;
            }

            .get-started-wrapper {
                margin: 32.5px auto 0 auto;

                span {
                    font-size: 16px;
                }

                .arrow {
                    width: 17px;
                    height: 17px;
                    .retina('/img/red-arrow@2x.png', 17px, 17px);
                }
            }

            #preview {
                width: 706px;
                height: 397px;
            }
        }

        // DESKTOP LISTING STYLES
        &.listing, &.recruitment {
            .info {
                .col {
                    width: 610px;
                    margin-right: 0;
                }

                h1 {
                    font-size: 58px;
                    line-height: 58px;
                    color: @remaxBlue;
                    max-width: 578px;
                }

                p {
                    font-size: 22px;
                    max-width: 610px;
                    line-height: 35px;
                    margin-top: 40px;
                }
            }

            .get-started-wrapper {
                margin-top: 100px;

                span {
                    .circularStdBold;
                    color: @remaxBlue;
                    font-size: 25px;
                }

                .arrow {
                    margin-top: 16.5px;
                    width: 27.5px;
                    height: 15.5px;
                    .retina('/img/blue-arrow@2x.png', 27.5px, 15.5px);
                    &.recruitment{
                        .retina('/img/blue-arrow-custom@2x.png', 27.5px, 15.5px);                        
                    }
                }
            }
        }

        &.recruitment{
            .info{
                h1{
                    color:@customBlue;
                }
            }

           .get-started-wrapper {
                span {
                    color: @customBlue;
                }
            }
        }

        // DESKTOP CUSTOM STYLES
        &.custom, &.iam {
            .hero {
                height: 372px;

                .bg{
                    background-image: url('/img/custom_hero_desktop.jpg');
                }

                .content {
                    .wide-rule-one, .wide-rule-two {
                        width: 1032px;
                        border-width:4px;
                    }

                    .wide-rule-one {
                        margin-bottom: 62px;
                    }

                    .wide-rule-two {
                        margin-top: 62px;
                    }

                    .banner-title {
                        font-size: 75px;
                    }
                }
            }

            .info {
                h1 {
                    max-width: 630px;
                }

                p {
                    max-width: 578px;
                    font-size: 22px;
                    line-height: 32px;
                }
            }
        }

        &.custom.canada{

            .hero{

                .bg{
                    background-image: url('../../img/welcome_hero_desktop_canada.jpg');
                }

                .content{
                    
                    .banner-title{
                        max-width:800px;
                        line-height: 70px;
                    }
                }
            }
            .info{
                h1{
                    max-width:710px;
                }
            }
        }

        &.iam {
            .info {
                padding-top: 150px;

                h1 {
                    font-size: 60px;
                }

                p {
                    max-width: 560px;
                    margin-top: 40px;
                }
            }

            video {
                margin-top: 40px;
                width: 706px;
                height: 397px;
            }

            .video-caption {
                margin-top: 20px;
                font-size: 18px;
                max-width: 706px;
            }
        }
    }
}
