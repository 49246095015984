.recruitment-video-page {
    .flex-center-justify;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 25px 20px 50px 20px;

    .custom-video-header {
        h1, p {
            color: @customBlue;
            text-align: center;
            //margin-bottom: 15px;
        }

        h1 {
            .circularStdBold;
            font-size: 16px;
        }

        p {
            .futuraPTBold;
            font-size: 21px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .rule {
            width:100%;
            height:26px;
            margin-bottom: 10px;
            .ccnr;
            .retina('../img/module_header_rule_dark_blue@2x.png', 57px, 1px);
        }
    }

    .download-all-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;

        .btn-download-all {
            .hover;
            width: 135px;
            font-size: 12px;
            padding: 0;
            line-height: 35px;
            box-sizing: content-box;

            &:hover {
                padding: 0;
                box-sizing: content-box;
            }
        }

        .info {
            display: inline-block;
            height: 18px;
            width: 18px;
            margin-left: 5px;
            margin-top: 3px;
            .retina('../img/info_icon@2x.png', 18px, 18px, false);
            background-repeat: no-repeat;
        }

        .tooltip {
            .circularStdBold;
            position: absolute;
            bottom: 30px;
            left: -20px;
            width: 206px;
            z-index: 2;
            letter-spacing: 0.02em;
            font-size: 9px;
            padding: 5px;
            text-align: center;
            border: 1px solid #949ca1;
            background: #c4c6c8;
            color: white;
        }
    }

    .video-container {
        .flex-center-justify;
        width: 320px;
        margin: 0 auto;
        flex-wrap: wrap;

        .custom-video-container {
            margin-bottom: 25px;

            video {
                width: 320px;
                height: 180px;
            }

            .custom-video-buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 320px;
                margin: 10px 0;

                .dl-copy-btn-wrapper {
                    .btn-dl-copy {
                        padding: 0 6px;
                        font-size: 10px;
                        height: 22px;
                        line-height: 22px;
                        background-color: @remaxBlue;
                        border: 2px solid transparent;
                        box-sizing: content-box;
                        transition: all 100ms @easeOutQuad;

                        &:first-child {
                            margin-right: 10px;
                        }

                        &:hover {
                            color: @remaxBlue;
                            background-color: white;
                            border: 2px solid @remaxBlue;
                        }
                    }
                }

                .share-btn-wrapper {
                    display: flex;
                    align-items: center;

                    .btn-s {
                        .flex-center-justify;
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        margin-right: 6px;
                        background: white;
                        border: 2px solid @remaxBlue;

                        svg {
                            rect, path {
                                fill: @remaxBlue;
                            }
                        }

                        &.share-rollover {
                            transition: all 100ms @easeOutQuad;

                            &:hover {
                                background: @remaxBlue;

                                svg {
                                    rect, path {
                                        fill: white;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            margin: 0;
                        }

                        &.btn-s-facebook {
                            svg {
                                height: 13px;
                            }
                        }

                        &.btn-s-twitter {
                            svg {
                                height: 10px;
                            }
                        }

                        &.btn-s-email {
                            svg {
                                height: 10px;
                            }
                        }

                        &.btn-s-linkedin {
                            svg {
                                height: 11px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media @laptop {
        padding-bottom: 0;
        padding-top: 0;

        .custom-video-header {
            h1, p {
                //margin-bottom: 28px;
            }

            h1 {
                font-size: 19px;
            }

            p {
                font-size: 28px;
                letter-spacing: 5px;
            }

            .rule {
                margin-bottom: 10px;
            }
        }

        .download-all-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            // margin-left: 28px;

            .btn-download-all {
                width: 184px;
                font-size: 15px;
                padding: 0;
                line-height: 35px;
            }

            .info {
                margin-left: 10px;
            }

            .tooltip {
                top: 6px;
                right: -221px;
                left: unset;
                bottom: unset;
                width: 206px;
            }
        }

        .video-container {
            width: 876px;

            .custom-video-container {
                margin-bottom: 50px;

                &:nth-child(odd) {
                    margin-right: 50px;
                }

                video {
                    width: 413px;
                    height: 231px;
                }

                .custom-video-buttons {
                    margin: 10px 0;
                    width: 413px;

                    .dl-copy-btn-wrapper {
                        .btn-dl-copy {
                            padding: 0 17px;
                            font-size: 12px;
                            height: 25px;
                            line-height: 25px;

                            &:first-child {
                                margin-right: 10px;
                            }
                        }
                    }

                    .share-btn-wrapper {
                        .btn-s {
                            width: 25px;
                            height: 25px;
                            margin-right: 8px;

                            &.btn-s-facebook {
                                svg {
                                    height: 13px;
                                }
                            }

                            &.btn-s-twitter {
                                svg {
                                    height: 11px;
                                }
                            }

                            &.btn-s-email {
                                svg {
                                    height: 11px;
                                }
                            }

                            &.btn-s-linkedin {
                                svg {
                                    height: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media @desktop {
        .custom-video-header {
            h1, p {
                //margin-bottom: 39px;
            }

            h1 {
                font-size: 29px;
            }

            p {
                font-size: 42px;
                letter-spacing: 7px;
            }

            .rule {
                margin-bottom: 20px;
            }
        }

        .download-all-wrapper {
            margin-bottom: 39px;
            // margin-left: 42px;

            .btn-download-all {
                width: 276px;
                font-size: 23px;
                padding: 0;
                height: 53px;
                line-height: 53px;
            }

            .info {
                margin-left: 15px;
                display: inline-block;
                height: 27px;
                width: 27px;
                .retina('../img/info_icon@2x.png', 27px, 27px, false);
                background-repeat: no-repeat;
            }

            .tooltip {
                top: 9px;
                right: -325px;
                left: unset;
                bottom: unset;
                width: 288px;
                padding: 12px;
                border: 2px solid #949ca1;
                font-size: 13px;
            }
        }

        .video-container {
            width: 1315px;

            .custom-video-container {
                margin-bottom: 75px;

                &:nth-child(odd) {
                    margin-right: 75px;
                }

                video {
                    width: 620px;
                    height: 347px;
                }

                .custom-video-buttons {
                    margin: 15px 0;
                    width: 620px;

                    .dl-copy-btn-wrapper {
                        .btn-dl-copy {
                            padding: 0 26px;
                            font-size: 18px;
                            height: 38px;
                            line-height: 38px;

                            &:first-child {
                                margin-right: 15px;
                            }
                        }
                    }

                    .share-btn-wrapper {
                        .btn-s {
                            width: 38px;
                            height: 38px;
                            margin-right: 12px;

                            &.btn-s-facebook {
                                svg {
                                    height: 19px;
                                }
                            }

                            &.btn-s-twitter {
                                svg {
                                    height: 16px;
                                }
                            }

                            &.btn-s-email {
                                svg {
                                    height: 16px;
                                }
                            }

                            &.btn-s-linkedin {
                                svg {
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
