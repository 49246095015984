
.legal{
    .circularStdBook;
    width: 100%;
    padding-bottom: 12px;
    text-align: center;
    font-size: 8px;
    line-height: 10px;

    &.white {
        background-color: white;
        color: @textGrey;

        a {
            color: @textGrey;
        }
    }

    &.blue {
        background-color: @bgBlue;
        color: white;

        a {
            color: white;
        }
    }

    &.dark-blue {
        background-color: @bgDarkBlue;
        color: white;

        a {
            color: white;
        }
    }

    @media @laptop {
        font-size: 9px;
        line-height: 11px;
        padding-top: 20px;

        span {
            &:first-child {
                margin-right: 3px;
            }
        }
    }

    @media @desktop {
        font-size: 12px;
        line-height: 14px;

        &.snap {
            position: relative;
        }

        span {
            &:first-child {
                margin-right: 6px;
            }
        }
    }
}