
@formMargin:4px;
@formElHeight:30px;
@formElPadding:10px;

.contact-info {

    .module-buttons {
        margin-top: 30px;
    }

    .add-more{
        display: none;

        &.visible{
            display: block;
        }
    }

    .hide-more{
        display: none;

        &.visible{
            display: block;
        }
    }

    .more{
        display: none;

        &.visible{
            display: block;
        }
    }

    .formEl{
        width:100%;
        display: inline-block;
        height:@formElHeight;
        color:@remaxBlue;
        background:white;
        .circularStdBold;
        margin-top:@formMargin;
        border:none;
        border-radius:0px;
        font-size:12px;
        line-height:@formElHeight;
        padding:0px @formElPadding 0px @formElPadding;
        margin:@formMargin 0px 0px 0px;
        box-sizing:border-box;
        -webkit-appearance: none;

        &::-webkit-input-placeholder {
          color: @bgBlue;
        }
        &::-moz-placeholder {
          color: @bgBlue;
        }
        &:-ms-input-placeholder {
          color: @bgBlue;
        }
        &:-moz-placeholder {
          color: @bgBlue;
        }
    }

    input{
        .formEl;

        &:-webkit-textfield-decoration-container {
            display: none;
        }

        &.office_name{
            text-indent:60px;
            margin:0px;
        }

        &.license_number{
            width:calc(50% - (@formMargin / 2));
        }

        &.mls_number{
            width:calc(50% - (@formMargin / 2));
            margin-left:@formMargin;
        }
    }

    .input-flex-wrapper {
        display: flex;

        .err-state-wrapper {
            &.name{
                width:calc(50% - (@formMargin / 2));
            }

            &.title{
                width:calc(50% - (@formMargin / 2));
                margin-left:@formMargin;
            }
        }
    }

    .office {
        position: relative;
        width:100%;
        height:@formElHeight;
        margin:@formMargin 0px 0px 0px;

        &.office-err {
            height: 58px;
        }

        .prefix {
            font-size:12px;
            .circularStdBold;
            position: absolute;
            top:0px;
            left:0px;
            width:60px;
            color:@remaxBlue;
            line-height:@formElHeight + 1px;
            padding-left:10px;
            box-sizing: border-box;
        }
    }

    select{
        .formEl;
        color:@bgBlue;
        background-image: url('../../img/select_arrow@2x.png');
        background-position: calc(100% - 5px) calc(1em + -6px);
        background-size: 17px 17px;
        background-repeat: no-repeat;
        cursor:pointer;

        &.chosen{
            color:@remaxBlue;
        }
    }

    input, select {
        font-variant-ligatures: none;

        &.show-error {
            &:invalid {
                border: 1px solid #dc1c2e;
                background-color: #fbe7e9;
            }
        }
    }

    .input-error {
        .circularStdBook;
        font-size: 11px;
        color: #dc1c2e;
        letter-spacing: 0.02em;
    }

    .toggle-more{

        width:100%;
        height:18px;
        padding-top:5px;
        color:white;
        .circularStdBold;
        font-size:12px;

        .btn-toggle-more{
            vertical-align: top;
            display: inline-block;
            width:17px;
            height:17px;
            background:@royalBlue;
            border:none;
            margin-right:5px;
            text-align:center;

            .icon-plus{
                margin-top:4px;
                transform-origin: center center;
                transition:transform 300ms @easeOutQuint;
            }

            .icon-minus{
                margin-bottom: 3px;
            }

            &:hover{
                .icon-plus{
                    transform:rotate(90deg);
                }
            }
        }
    }

    .photo-section{
        text-align:center;
        width:100%;

        p{
            .circularStdBook;
            font-size: 12px;
            line-height: 14px;
            color:white;
        }

        .msg-span {
            margin-top:8px;
            .circularStdBold;
            color: @textGrey;
            font-size: 10px;
            line-height: 11px;

            i {
                line-height: 16px;
                opacity: 0.7;
            }
        }
        
        .btn-upload{
            margin-top:10px;
            border:2px solid @remaxRed;
            transition:all 100ms @easeOutQuad;

            &:hover{
                background:white;
                color:@remaxRed;
                box-sizing: border-box;
            }
        }
    }

    .prominence-check-span {
        display: flex;
        align-items: center;
        height: 35px;
        margin: 6px 0 0 0;

        .iam-checkbox {
            margin-right: 13px;
        }

        .msg-span {
            .circularStdBold;
            color: @textGrey;
            font-size: 10px;
            line-height: 11px;

            i {
                line-height: 16px;
                opacity: 0.7;
            }
        }

        &.white {
            .iam-checkbox {
                .checkbox-bg {
                    border: 1px solid white;
                }
            }

            .msg-span {
                color: white;
            }
        }
    }

    &.listing, &.custom, &.recruitment, &.iam {
        input, select {
            border: 1px solid #656565;
        }

        .select-clips-dialogue {
            .circularStdBold;
            color: @textGrey;
            margin: 19px auto 0 auto;
            width: 349px;
            font-size: 12px;
            text-align: center;
        }

        .headshot-dialogue {
            .circularStdBold;
            color: white;
            font-size: 12px;
            line-height: 19px;
        }

        .btn-select-clips {
            .hover;
            width: 184px;
            margin: 27px 0 0 calc(50% - 92px);
        }

        .btn-upload-headshot {
            margin-top: 27px;
            .hover;
        }

        .no-photo {
            color: white;
            font-style: italic;
            opacity: 0;
            pointer-events: none;

            .checkbox {
                .bg {
                    background: white;
                }
            }
        }

        .toggle-more {
            .btn-toggle-more {
                background: @bgBlue;
            }
        }

        .error-bar-wrapper {
            margin-top: 15px;
        }

        .preview-wrapper {
            margin-top: 23px;
        }
    }

    &.listing {
        select {
            background-image: url('../../img/select_arrow_baby_blue@2x.jpg');
            background-position: calc(100% - 5px) calc(1em + -6px);
            background-size: 17px 17px;
            background-repeat: no-repeat;
        }
    }

    &.listing-no-border {
        input, select {
            border: none;
        }
    }

    &.listing-error-color {
        .input-error {
            color: #ffb8b8;
        }
    }

    &.custom, &.recruitment, &.iam {
        .headshot-dialogue {
            color: @textGrey;
        }

        .no-photo {
            color: @textGrey;
        }
    }

    &.custom, &.recruitment {
        select {
            background-image: url('../../img/select_arrow_custom_blue@2x.png');
            background-position: calc(100% - 5px) calc(1em + -6px);
            background-size: 17px 17px;
            background-repeat: no-repeat;
        }

        .toggle-more {
            color: @customBlue;

            .btn-toggle-more {
                background: @customBlue;
            }
        }
    }

    &.iam {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active  {
            -webkit-text-fill-color: @bgDarkBlue !important;
        }

        .no-photo {
            display: none;
        }

        input, select {
            border: 1px solid @textGrey;
            color: @bgDarkBlue;
        }

        .formEl {
            color: @bgDarkBlue;
        }

        .toggle-more {
            color: @bgDarkBlue;

            .btn-toggle-more {
                background: @bgDarkBlue;
            }
        }

        .prefix {
            color: @bgDarkBlue;
        }
    }

    @media @laptop {
        .flex-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            .formEl {
                margin-top: 10px;
            }

            .col {
                width: 470px;

                &:first-child {
                    margin-right: 20px;
                }

                select {
                    margin-top: 3px;
                    background-position: calc(100% - 8px) calc(1em + -6px);
                    background-size: 23px 23px;
                }

                input, select {
                    width: 470px;
                    height: 40px;
                    font-size: 14px;
                    letter-spacing: 0.02em;

                    &.license_number, &.mls_number {
                        width: 233px;
                    }

                    .mls-number {
                        margin-left: 10px;
                    }
                }

                .input-flex-wrapper {
                    input {
                        width: 100%;
                    }

                    .err-state-wrapper {
                        &.name{
                            width:calc(50% - (@formMargin / 2));
                        }

                        &.title{
                            width:calc(50% - (@formMargin / 2));
                        }
                    }
                }

                .office{
                    height: 40px;
                    width: 470px;

                    &.office-err {
                        height: 64px;
                    }

                    input {
                        text-indent: 65px;
                    }

                    .prefix {
                        font-size: 14px;
                        line-height: 41px;
                    }
                }

                .toggle-more {
                    width: 470px;

                    .icon-minus {
                        margin-top: 0;
                        margin-bottom: 3px;
                    }
                }

                .prominence-check-span {
                    height: 42px;

                    .msg-span {
                        font-size: 12px;
                        line-height: 13px;

                        i {
                            line-height: 18px;
                        }
                    }
                }
            }
        }

        &.listing, &.custom, &.recruitment, &.iam {
            .preview-wrapper {
                margin-top: 3px;
            }

            .btn-upload-headshot, .btn-select-clips {
                padding: 0;
                line-height: 35px;
                font-size: 15px;
                box-sizing: content-box;

                &:hover {
                    box-sizing: content-box;
                }
            }

            .btn-upload-headshot {
                width: 200px;
            }

            .btn-select-clips {
                width: 185px;
            }
        }

        &.listing {
            .select-clips-dialogue {
                margin: 51px auto 0 auto;
            }
        }

        .photo-section.simple{
            text-align: left;
            margin-top:25px;
        }

        .photo-section{
            .msg-span {
                font-size: 12px;
                line-height: 13px;

                i {
                    line-height: 18px;
                }
            }
        }
    }

    @media @desktop {
        .flex-wrapper {
            .col {
                width: 706px;

                &:first-child {
                    margin-right: 28px;
                }

                select {
                    margin-top: 4px !important;
                    background-position: calc(100% - 13px) calc(1em + -8px);
                    background-size: 35px 35px;
                }

                input, select {
                    .circularStdBold;
                    margin-top: 7px;
                    width: 706px;
                    height: 60px;
                    font-size: 22px;

                    &.name, &.title {
                        width: 706px;
                    }

                    &.license_number, &.mls_number {
                        width: 349.5px;
                    }

                    &.mls_number {
                        margin-left: 7px;
                    }
                }

                .input-flex-wrapper {
                    input {
                        width: 100%;
                    }

                    .err-state-wrapper {
                        &.name{
                            width:calc(50% - (7px / 2));
                        }

                        &.title{
                            width:calc(50% - (7px / 2));
                            margin-left: 7px;
                        }
                    }
                }

                .office{
                    height: 60px;
                    width: 706px;
                    margin: 7px 0 0 0;

                    &.office-err {
                        height: 84px;
                    }

                    input {
                        margin-top: 0;
                        text-indent: 100px;
                    }

                    .prefix {
                        font-size: 22px;
                        line-height: 60px;
                    }
                }

                .input-error {
                    font-size: 14px;
                }

                input, select {
                    &.show-error {
                        &:invalid {
                            border: 2px solid #dc1c2e;
                        }
                    }
                }

                .toggle-more {
                    width: 706px;
                    font-size: 18px;

                    .btn-toggle-more {
                        width: 21px;
                        height: 21px;

                        .icon-plus {
                            margin-top: 0;
                            margin-bottom: 5px;
                        }

                        .icon-minus {
                            margin-top: 0;
                            margin-bottom: 8px;
                        }
                    }
                }

                .prominence-check-span {
                    height: 66px;

                    .msg-span {
                        font-size: 18px;
                        line-height: 19px;

                        i {
                            line-height: 24px;
                        }
                    }
                }
            }
        }

        &.listing, &.custom, &.recruitment, &.iam {
            .select-clips-dialogue {
                margin: 76px auto 0 auto;
                width: 523px;
                font-size: 19px;
            }

            .btn-upload-headshot, .btn-select-clips {
                height: 53px;
                line-height: 53px;
                font-size: 23px;
            }

            .btn-upload-headshot {
                width: 300px;
            }

            .btn-select-clips {
                width: 278px;
                margin-left: calc(50% - 138px);
            }

            .headshot-dialogue {
                font-size: 18px;
                line-height: 29px;
            }

            .btn-upload-headshot {
                width: 300px;
                height: 53px;
                font-size: 22px;
                margin-top: 41px;
            }
        }

        .photo-section.simple{
            text-align: left;
            margin-top:35px;
        }

        .photo-section{
            .msg-span {
                margin-top:15px;
                font-size: 18px;
                line-height: 19px;

                i {
                    line-height: 18px;
                }
            }
        }
    }

}
