@indicatorSize:24px;

.selection-indicator{
    position: absolute;
    width:@indicatorSize;
    height:@indicatorSize;
    border-radius: 50%;
    border:2px solid white;
    box-sizing: border-box;
    top:7px;
    right:7px;
    .futuraPTBold;
    color:white;
    text-align:center;
    line-height:@indicatorSize - 4px;
    
    transition: background-color 50ms @easeOutQuad;

    &.selected{
        background: @royalBlue;

        .check-mark {
            width: 12px;
            height: 9px;
            .retina('../../img/checkmark_white@2x.png', 12px, 9px, true);
        }
    }

    &.track-select {
        .flex-center-justify;
    }

    @media @desktop {
        top: 12px;
        right: 12px;
        width: 45px;
        height: 45px;
        border: 3px solid white;
        font-size: 28px;
        line-height: 40px;

        &.selected {
            .check-mark {
                width: 24px;
                height: 18px;
                .retina('../../img/checkmark_white@2x.png', 24px, 18px, true);
            }
        }
    }
}