
.upload-photo {

    .flex-wrapper {
        .description {
            margin-bottom: 25px;
        }

        p {
            font-size: 14px;
        }

        .col {
            text-align: center;

            &.upload-preview {
                margin-bottom: 30px;
            }

            .preview {
                margin-bottom: 0;
            }
        }
    }

    .btn-upload-photo{
        margin-top:4px;
        border:2px solid @remaxRed;
        transition:all 100ms @easeOutQuad;

        &:hover{
            background:white;
            color:@remaxRed;
            box-sizing: border-box;
        }
    }

    @media @laptop {
        .flex-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .col {
                &:first-child {
                    margin-right: 20px;
                }

                &.desktop {
                    width: 470px;

                    .description {
                        margin-top: 0;
                    }

                    p {
                        .circularStdBook;
                        font-size: 14px;
                        max-width: 350px;
                        line-height: 20px;
                        letter-spacing: 0.025em;
                        color:@textGrey;
                        margin: 10px auto 20px auto;
                    }

                    .btn-upload {
                        .futuraPTHeavy;
                        padding: 0 23px;
                        font-size: 14px;
                        height: 30px;
                        line-height: 27px;
                        letter-spacing: 0.2em;
                    }
                }
            }
        }
    }

    @media @desktop {
        .flex-wrapper {
            .col {
                &:first-child {
                    margin-right: 28px;
                }

                &.desktop {
                    width: 706px;

                    p {
                        font-size: 22px;
                        max-width: 579px;
                        line-height: 32px;
                        margin: 20px auto 35px auto;
                    }

                    .btn-upload {
                        padding: 0 36px;
                        font-size: 17px;
                        height: 40px;
                        line-height: 37px;
                    }
                }
            }
        }
    }
}

#photo-input {
    display: none;
}