.btn{
    cursor:pointer;
    i{
        display: inline-block;
        &.icon-next{
            .retina('/img/icon_arrow_next@2x.png', 9px, 9px, true);
        }
        &.icon-previous{
            .retina('/img/icon_arrow_next@2x.png', 9px, 9px, true);
            transform:rotate(180deg);
        }
        &.icon-plus{
            .retina('../img/icon_plus_mobile@2x.png', 8px, 8px, true);
        }
        &.icon-minus{
            .retina('../img/icon_minus_mobile@2x.png', 8px, 2px, true);
        }
    }
}

.btn-default{
    .futuraPTHeavy;
    .no-select;
    height:35px;
    text-transform:uppercase;
    color:white;
    background: #b2292e;
    display: inline-block;
    font-size: 11px;
    letter-spacing: 2px;
    vertical-align: top;
    padding: 9px 16px;
    box-sizing: border-box;
    text-align:center;

    .icon-next{
        margin-left:3px;
    }
}

.btn-previous{

    background:none;
    border:1px solid white;

    .icon-previous{
        margin-right:10px;
    }
}

.btn-next{
    .icon-next{
        margin-left:10px;
    } 
}

.hover {
    border: 2px solid @remaxRed;
    transition:all 100ms @easeOutQuad;

    &:hover{
        background:white;
        color:@remaxRed;
        box-sizing: border-box;
    }
}

.module-buttons{
    .flex-center-justify;
    margin-top:9px;
    width:100%;
    height:35px;
    text-align:center;
    
    a {
        text-decoration: unset;
    }
    
    .btn {
        .flex-center-justify;
        width:150px;
        top:0px;
        font-size: 10px;

        p {
            margin: 0;
        }
    }

    .btn-previous {
        margin-right: 20px;

        &.clips-margin {
            margin-right: 13px;
        }
    }

    .btn-previous{
        font-style: unset;

        .white &, .custom-white & {
            border:1px solid @textGrey;
            color: @textGrey;

            .icon-previous{
                .retina('/img/icon_arrow_next_grey@2x.png', 9px, 9px, true);
            }
        }
    }
}
