
#listing_preview_two{
	
	transform-origin:top left;	
	position: absolute;
	
	opacity: 0.85;
    filter: blur(1px);	

	.info{
	    position: absolute;
	    top:0;
	    left:0;
	    width:100%;
	    height:100%;
	    text-align: center;
	    color:white;
	    padding:35px;
	    box-sizing: border-box;
	    transform: rotate(0.3deg);
	}

	.listing_address_1, .listing_address_2{
	    .circularStdBold;
	    font-size: 52px;
	    letter-spacing: 0px;
	    height: 60px;
	    line-height: 70px;
	}

	.listing_details{
	    .circularStdBook;
	    font-size: 37px;
	    letter-spacing: 2px;
	    line-height: 42px;
	    margin-top: 18px;
	    margin-bottom: 32px;
	}

	.extra_details, .call_to_action{
	    .circularStdMedium;
	    font-size: 30px;
	    height: 20px;
	    letter-spacing: 5px;
	    text-transform: uppercase;
	    line-height: 40px;
	    margin-top: 6px;
	}

	.extra_details{
	    margin-top:10px;
	}

	.h-rule{
	    width:100%;
	    height:4px;
	    background: white;
	}

	.h-rule.top{
	    margin-top:66px;
	    margin-bottom: 30px;
	}

	.h-rule.bottom{
	    margin-bottom: 61px;
	}

	.field{
	    display: inline-block;
	    white-space: nowrap;
	}
}