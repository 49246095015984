.listing-video-page, .custom-share-page {
    .flex-center-justify;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 25px 20px 50px 20px;

    &.deeplink {
        padding: 50px 20px;
    }

    .listing-video-header {
        h1, p {
            color: @remaxBlue;
            text-align: center;
            margin-bottom: 15px;
        }

        h1 {
            .circularStdBold;
            font-size: 16px;
        }

        p {
            .futuraPTBold;
            font-size: 21px;
            text-transform: uppercase;
            letter-spacing: 1px;
            max-width: 320px;
        }

        .exp-error{
            font-size:12px;
            margin-bottom:10px;
            color:@remaxRed;
            letter-spacing:0px;
        }

        .rule {
            width:100%;
            height:26px;
            margin-bottom: 20px;
            .ccnr;
            .retina('../img/module_header_rule_blue@2x.png', 57px, 1px);
        }
    }

    video {
        width: 320px;
        height: 180px;
        background:black;
    }

    .listing-video-buttons, .custom-share-buttons {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 320px;
        margin: 10px 0;

        .custom-share-page& {
            margin-bottom: 50px;
        }

        .dl-copy-btn-wrapper {
            position: relative;

            .btn-dl-copy {
                padding: 0 4px;
                font-size: 10px;
                height: 22px;
                line-height: 22px;
                background-color: @remaxBlue;
                border: 2px solid transparent;
                box-sizing: content-box;
                transition: all 100ms @easeOutQuad;

                &:first-child {
                    margin-right: 5px;
                }

                &:hover {
                    color: @remaxBlue;
                    background-color: white;
                    border: 2px solid @remaxBlue;
                }
            }

            .info {
                display: inline-block;
                height: 18px;
                width: 18px;
                margin-left: 5px;
                margin-top: 3px;
                .retina('../img/info_icon@2x.png', 18px, 18px, false);
                background-repeat: no-repeat;
            }

            .tooltip {
                .circularStdBold;
                position: absolute;
                bottom: 35px;
                left: -20px;
                width: 206px;
                z-index: 2;
                letter-spacing: 0.02em;
                font-size: 9px;
                padding: 5px;
                text-align: center;
                border: 1px solid #949ca1;
                background: #c4c6c8;
                color: white;
            }
        }

        .share-btn-wrapper {
            display: flex;
            align-items: center;

            .btn-s {
                .flex-center-justify;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                margin-right: 4px;
                background: white;
                border: 2px solid @remaxBlue;

                svg {
                    rect, path {
                        fill: @remaxBlue;
                    }
                }

                &.share-rollover {
                    transition: all 100ms @easeOutQuad;

                    &:hover {
                        background: @remaxBlue;

                        svg {
                            rect, path {
                                fill: white;
                            }
                        }
                    }
                }

                &:last-child {
                    margin: 0;
                }

                &.btn-s-facebook {
                    svg {
                        height: 13px;
                    }
                }

                &.btn-s-twitter {
                    svg {
                        height: 10px;
                    }
                }

                &.btn-s-email {
                    svg {
                        height: 10px;
                    }
                }

                &.btn-s-linkedin {
                    svg {
                        height: 11px;
                    }
                }
            }
        }
    }

    .make-another-wrapper {
        margin-top: 38px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .make-another-arrow-red {
            width: 8px;
            height: 14px;
            .retina('/img/make_another_arrow_red@2x.png', 8px, 14px);
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 15px;
            transition: transform 200ms @easeOutQuad;
        }

        .make-another-red-copy {
            .futuraPTHeavy;
            color: @remaxRed;
            font-size: 18px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        &:hover {
            .make-another-arrow-red {
                transform: translateX(-5px);
            }
        }
    }

    @media @laptop {
        padding-bottom: 0;
        padding-top: 0;

        .listing-video-header {
            h1, p {
                margin-bottom: 0;
            }

            h1 {
                font-size: 19px;
            }

            p {
                font-size: 28px;
                letter-spacing: 5px;
                max-width: 720px;
            }

            .exp-error{
                font-size:18px;
                letter-spacing: 1px;
                margin-bottom:15px;
            }

            .rule {
                margin-bottom: 33px;
            }
        }

        video {
            width: 607px;
            height: 341px;
        }

        .listing-video-buttons, .custom-share-buttons {
            margin: 15px 0;
            width: 607px;

            .dl-copy-btn-wrapper {
                .btn-dl-copy {
                    padding: 0;
                    width: 127px;
                    height: 26px;
                    line-height: 26px;
                    font-size: 11px;

                    &:first-child {
                        margin-right: 9px;
                    }
                }

                .info {
                    margin-left: 9px;
                    margin-top: 7px;
                }

                .tooltip {
                    top: 6px;
                    right: -221px;
                    left: unset;
                    bottom: unset;
                    width: 206px;
                }
            }

            .share-btn-wrapper {
                .btn-s {
                    width: 28px;
                    height: 28px;
                    margin-right: 7px;

                    &.btn-s-facebook {
                        svg {
                            height: 14px;
                        }
                    }

                    &.btn-s-twitter {
                        svg {
                            height: 12px;
                        }
                    }

                    &.btn-s-email {
                        svg {
                            height: 12px;
                        }
                    }

                    &.btn-s-linkedin {
                        svg {
                            height: 13px;
                        }
                    }
                }
            }
        }
    }

    .make-another-wrapper {
        margin: 76px 0;
    }

    @media @desktop {
        .listing-video-header {
            h1 {
                font-size: 29px;
            }

            p {
                font-size: 42px;
                letter-spacing: 7px;
                max-width: 1024px;
            }

            .rule {
                margin-bottom: 50px;
            }
        }

        video {
            width: 910px;
            height: 512px;
        }

        .listing-video-buttons, .custom-share-buttons {
            margin: 23px 0;
            width: 910px;

            .dl-copy-btn-wrapper {
                .btn-dl-copy {
                    width: 190px;
                    font-size: 15px;
                    height: 40px;
                    line-height: 40px;

                    &:first-child {
                        margin-right: 14px;
                    }
                }

                .info {
                    margin-left: 14px;
                    margin-top: 10px;
                    display: inline-block;
                    height: 27px;
                    width: 27px;
                    .retina('../img/info_icon@2x.png', 27px, 27px, false);
                    background-repeat: no-repeat;
                }

                .tooltip {
                    top: 9px;
                    right: -325px;
                    left: unset;
                    bottom: unset;
                    width: 288px;
                    padding: 12px;
                    border: 2px solid #949ca1;
                    font-size: 13px;
                }
            }

            .share-btn-wrapper {
                .btn-s {
                    width: 43px;
                    height: 43px;
                    margin-right: 10px;

                    &.btn-s-facebook {
                        svg {
                            height: 23px;
                        }
                    }

                    &.btn-s-twitter {
                        svg {
                            height: 21px;
                        }
                    }

                    &.btn-s-email {
                        svg {
                            height: 20px;
                        }
                    }

                    &.btn-s-linkedin {
                        svg {
                            height: 22px;
                        }
                    }
                }
            }
        }

        .make-another-wrapper {
            margin: 115px 0;

            .make-another-arrow-red {
                width: 12px;
                height: 21px;
                .retina('/img/make_another_arrow_red@2x.png', 12px, 21px);
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 23px;
            }

            .make-another-red-copy {
                color: @remaxRed;
                font-size: 27px;
                letter-spacing: 3px;
            }

            &:hover {
                .make-another-arrow-red {
                    transform: translateX(-8px);
                }
            }
        }
    }
}
