.standard-checkbox {
    margin:10px auto 10px auto;
    display: flex;
    width: 150px;
    max-width:315px;
    position: relative;

    @media @laptop {
        margin:20px auto 20px auto;
    }

    &:hover{
        .box{
            background: #F1F1F1;
        }
    }
    .box {
        height: 30px;
        width: 30px;
        border: 1px solid #A4D7F4;
        position: absolute;
        top:0px;
        left: 0px;
    	text-align: center;
    	cursor:pointer;
    }

    .icon-wrapper {
        svg {
            margin-left: 4px;
            margin-top: -5px;
        }
    }
    
    .cta {
        margin-left: 40px;
        font-family: 'CircularStd-Bold', sans-serif;
        font-size: 12px;
        line-height: 12.6px;
        color: #949CA1;
        margin-top: 9px;
    }
}