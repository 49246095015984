#iam_message_preview{
    transform-origin:top left;	
	position: absolute;
	color:white;
    background: url(https://d1haqt8qautnl4.cloudfront.net/iam_img/prominence-bg.jpg) 100% 100% no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    .name{
        font-family: 'Gotham-Medium';
        text-transform: uppercase;
        width:100%;
        bottom: 162px;
        left:0px;
        color:#e4ebee;
        font-size: 24px;
        letter-spacing: 1.1px;
        text-align: center;
        position: absolute;
    }

    .message {
        font-family: 'Gotham-Medium';
        font-size: 60px;
        color:#e4ebee;
        line-height: 80px;
        margin-bottom: 10px;
        width:964px;
        text-align: center;
        padding-left:468px;
        padding-right:468px;
        width:100%;
        box-sizing: border-box;
    }
}