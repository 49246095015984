.video-page {
    .flex-center-justify;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 50px;

    .video-header {
        .flex-center-justify;
        flex-direction: column;
        text-align: center;

        .desktop-step {
            display: none;
        }

        h1 {
            max-width: 320px;
            .futuraPTBold;
            font-size: 21px;
            color: @royalBlue;
            text-transform: uppercase;
            word-spacing: .16em;
        }

        p {
            max-width: 320px;
            margin-top: 15px;
            .circularStdBook;
            color:@textGrey;
            font-size: 14px;
            letter-spacing: 0.05em;
        }

        .rule {
            width: 57px;
            height: 1px;
            margin: 15px 0 20px 0;
            .retina('../img/module_header_rule_blue@2x.png', 57px, 1px);
        }
    }

    .tooltip {
        position: absolute;
        top: -45px;
        left: -183px;
        width: 206px;
        z-index: 2;
        padding-bottom: 5px;
        .circularStdBold;
        letter-spacing: 0.02em;
        font-size: 9px;
        padding: 5px 0;
        text-align: center;
        border: 1px solid #949ca1;
        background: #c4c6c8;
        color: white;
    }

    .flex-wrapper {
        video {
            width: 320px;
        }

        .video-buttons {
            .flex-center-justify;
            flex-direction: column;

            .consume-buttons {
                margin: 15px auto;
                .flex-center-justify;
                flex-direction: column;

                .btn-dl {
                    margin-bottom: 10px;
                }

                .btn-dl, .btn-copy {
                    width: 190px;
                    border: 2px solid @remaxRed;
                    font-size: 9px;

                    &.consume-rollover {
                        transition:all 100ms @easeOutQuad;
                        
                        &:hover{
                            background:white;
                            color:@remaxRed;
                            box-sizing: border-box;
                        }
                    }
                }

                .copy-wrapper {
                    width: 190px;
                    display: flex;
                    align-items: center;
                    position: relative;

                    .info {
                        position: absolute;
                        right: -23px;
                        display: block;
                        height: 18px;
                        width: 18px;
                        margin-left: 15px;
                        .retina('../img/info_icon@2x.png', 18px, 18px, false);
                        background-repeat: no-repeat;

                        &.dl-wrapper {
                            top: 7.5px;
                        }
                    }
                }
            }

            .share-buttons {
                .flex-center-justify;
                flex-direction: column;

                &.share-padding {
                    padding-bottom: 50px;
                }

                .share-label {
                    .futuraPTBold;
                    font-size: 10px;
                    color: @textGrey;
                    text-transform: uppercase;
                    letter-spacing: 0.2em;
                }

                .share-btn-wrapper {
                    display: flex;
                    align-items: center;

                    .btn-share {
                        .flex-center-justify;
                        width: 43px;
                        height: 43px;
                        border-radius: 50%;
                        margin-right: 14px;
                        background: white;
                        border: 2px solid @remaxRed;

                        svg {
                            rect, path {
                                fill: @remaxRed;
                            }
                        }
                        
                        &.share-rollover {
                            transition: all 100ms @easeOutQuad;

                            &:hover {
                                background: @remaxRed;

                                svg {
                                    rect, path {
                                        fill: white;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            margin: 0;
                        }

                        &.btn-share-facebook {
                            svg {
                                height: 26px;
                            }
                        }

                        &.btn-share-twitter {
                            svg {
                                height: 20px;
                            }
                        }

                        &.btn-share-email {
                            svg {
                                height: 19px;
                            }
                        }

                        &.btn-share-linkedin {
                            svg {
                                height: 22px;
                            }
                        }
                    }
                }
            }

            .make-another {
                .futuraPTHeavy;
                .flex-center-justify;
                cursor: pointer;
                text-transform: uppercase;
                font-size: 11px;
                color: @remaxBlue;
                letter-spacing: 0.2em;
                margin-top: 65px;

                .back-arrow {
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;

                    .retina('../img/make-another-arrow@2x.png', 12px, 12px);
                }
            }
        }
    }

    @media @laptop {
        padding-bottom: 0;
        
        .video-header {
            padding-bottom: 20px;

            .desktop-step {
                .superClarendonItalic;
                display: block;
                letter-spacing: 0.20em;
                font-weight: 200;
                margin-bottom: 17px;
                color: @royalBlue;
            }

            h1 {
                .futuraPTBold;
                max-width: 760px;
                font-size: 28px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: .16em;
                color: @royalBlue;
            }

            p {
                font-size: 16px;
                margin-top: 20px;
                max-width: 760px;
            }

            .rule {
                margin: 20px 0;
            }
        }

        .flex-wrapper {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            padding-bottom: 50px;

            video {
                width: 720px;
                padding-bottom: 0;
            }

            .video-buttons {
                margin-left: 20px;
                align-self: stretch;
                align-items: flex-start;
                justify-content: space-between;
                flex-grow: 1;
                padding-bottom: 0;

                .consume-buttons {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 0 0 17px 0;

                    .btn-dl {
                        margin-right: 0;
                        margin-bottom: 15px;
                    }

                    .btn-dl, .btn-copy {
                        width: 225px;
                        height: 35px;
                        padding: 0;
                        font-size: 13px;
                        line-height: 30px;
                        letter-spacing: .2em;
                    }

                    .copy-wrapper {
                        width: auto;
                    }
                }

                .share-buttons {
                    align-items: flex-start;

                    .share-btn-wrapper {
                        .btn-share {
                            margin-right: 13px;
                        }
                    }
                }

                .make-another {
                    margin-top: 0;
                    font-size: 14px;
                    line-height: 14px;

                    .back-arrow {
                        width: 14px;
                        height: 14px;
                        margin-right: 5px;

                        .retina('../img/make-another-arrow@2x.png', 14px, 14px);
                    }
                }
            }
        }
    }

    @media @desktop {

        .video-header {
            padding-bottom: 30px;

            .desktop-step {
                margin-bottom: 17px;
                font-size: 22px;
            }

            h1 {
                max-width: 1140px;
                font-size: 42px;
            }

            p {
                font-size: 22px;
                margin-top: 28px;
                max-width: 1140px;
            }

            .rule {
                width: 114px;
                height: 2px;
                margin: 30px 0;
                .retina('../img/module_header_rule_blue@2x.png', 114px, 2px);
            }
        }

        .tooltip {
            padding-bottom: 10px;
            left: -273px;
            top: -75px;
            width: 288px;
            border: 2px solid #949ca1;
            padding: 12px;
            font-size: 13px;
        }

        .flex-wrapper {
            video {
                width: 1080px;
            }

            .video-buttons {
                margin-left: 28px;

                .consume-buttons {
                    margin: 0 0 25px 0;

                    .btn-dl {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }

                    .btn-dl, .btn-copy {
                        width: 340px;
                        height: 52px;
                        font-size: 21px;
                        line-height: 52px;
                    }

                    .copy-wrapper {
                        .info {
                            right: -35px;
                            height: 27px;
                            width: 27px;
                            margin-left: 10px;
                            .retina('../img/info_icon@2x.png', 27px, 27px);

                            &.dl-wrapper {
                                top: 9.5px;
                            }
                        }
                    }
                }

                .share-buttons {
                    .share-label {
                        font-size: 20px;
                    }

                    .share-btn-wrapper {
                        .btn-share {
                            width: 64.5px;
                            height: 64.5px;
                            border: 3px solid @remaxRed;
                            margin-right: 20px;

                            &.btn-share-facebook {
                                svg {
                                    height: 40px;
                                }
                            }

                            &.btn-share-twitter {
                                svg {
                                    height: 31px;
                                }
                            }

                            &.btn-share-email {
                                svg {
                                    height: 29px;
                                }
                            }

                            &.btn-share-linkedin {
                                svg {
                                    height: 35px;
                                }
                            }
                        }
                    }
                }

                .make-another {
                    font-size: 22px;
                    line-height: 22px;

                    .back-arrow {
                        width: 22px;
                        height: 22px;
                        margin-right: 10px;

                        .retina('../img/make-another-arrow@2x.png', 22px, 22px);
                    }
                }
            }
        }
    }
}
