.aspect-ratio-checkbox {

    display: inline-block;
    vertical-align: top;
    margin-right:4px;
    position: relative;
    height: 15px;
    width: 15px;
    border: 1px solid #A4D7F4;
    top:0px;
    left: 0px;
    text-align: center;
    cursor:pointer;

    &:hover{
        background: #F1F1F1;
    }

    svg {
        position:absolute;
        width:17px;
        height:15px;
        top:-2px;
        left:2px;
    }

    @media @desktop{

        margin-right:8px;
        width:20px;
        height:20px;

        svg{
            width:21px;
            height:17px;
            top:-1px;
            left:1px;
        }
    }
}