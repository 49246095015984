.modal-wrapper {
    position: fixed;
    top: 0;
    z-index: 10001;
    height: 100%;
    width: 100%;

    .bg {
        .layer;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 320px;
        height: 220px;
        margin-left: -160px;
        margin-top: -110px;
        background-color: white;
        .flex-center-justify;
        flex-direction: column;

        .modal-close-btn {
            position: absolute;
            top: 3px;
            right: 5px;
            width: 12px;
            height: 12px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &.browser-warning {
            height: 240px;
            margin-top: -120px;

            a {
                .circularStdBold;
                border-bottom: 1px solid @remaxBlue;
                color: @remaxBlue;
                letter-spacing: 0.05em;
                text-decoration: none;
                font-size: 14px;
                margin-top: 5px;
            }
        }

        &.cropper {
            height: 430px;
            margin-top: -215px;

            .crop-wrapper {
                margin-top: 16px;

                img {
                    max-height: fit-content;
                }

                &.adjust {
                    max-height: 200px;

                    img {
                        max-height: 200px;
                    }
                }
            }

            .btn-crop {
                margin-top: 16px;
                border: 2px solid @remaxRed;
                transition:all 100ms @easeOutQuad;
                padding: 8px 16px;

                &:hover{
                    background:white;
                    color:@remaxRed;
                    box-sizing: border-box;
                }
            }
        }

        &.listing-cropper {
            height: 420px;
            margin-top: -210px;
            display: block;

            .title {
                margin: 15px auto 5px auto;
                text-align: center;
            }

            .listing-cropper-row {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .select-listing-pic-buttons-col {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    width: 100%;
                    padding: 0 15px;
                    box-sizing: border-box;

                    .listing-crop-btn-wrapper {
                        margin-bottom: 15px;
                        display: flex;
                        align-items: center;

                        .btn-select-listing-photo {
                            .hover;
                            width: 80px;
                            height: 30px;
                            font-size: 8px;
                            padding: 1px 0;

                            &.disabled {
                                background-color: #c4c6c8;
                                border: 2px solid #c4c6c8;
                                transition: none;

                                &:hover {
                                    color: white;
                                }
                            }

                            &.active {
                                background-color: white;
                                color: @remaxRed;
                                border: 2px solid @remaxRed;

                                &:hover {
                                    color: @remaxRed;
                                }
                            }
                        }

                        .thumbnail {
                            display: block;
                            position: relative;
                            width: 50px;
                            height: 50px;
                            margin-left: 19px;

                            .edit-ico {
                                position: absolute;
                                top: -5px;
                                left: -5px;
                                width: 14px;
                                height: 14px;
                                background-image: url("../../img/edit.svg");
                                background-size: 8px 8px;
                                background-position: center center;
                                background-repeat: no-repeat;
                                cursor: pointer;
                                border-radius: 50%;
                                background-color: white;
                                border: 2px solid @remaxBlue;
                            }

                            .remove-ico {
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                width: 14px;
                                height: 14px;
                                background-image: url("../../img/remove-thumb-icon.svg");
                                background-size: 8px 8px;
                                background-position: center center;
                                background-repeat: no-repeat;
                                cursor: pointer;
                                border-radius: 50%;
                                background-color: white;
                                border: 2px solid @remaxBlue;

                            }
                        }
                    }
                }

                .listing-cropper-tool-col {
                    .flex-center-justify;
                    width: 214px;
                    height: 151px;
                    margin: auto;
                    flex-direction: column;
                    border: 3px dashed #c4c6c8;

                    &.editing {
                        border: 3px solid transparent;
                        height: auto;

                        .crop-wrapper {
                            &.adjust {
                                max-height: 170px;

                                img {
                                    max-height: 170px;
                                }
                            }
                        }
                    }

                    .listing-cropper-dialogue {
                        .circularStdBold;
                        margin-top: 5px;
                        font-size: 10px;
                        color: @textGreyDarker;
                        text-align: center;
                    }

                    .btn {
                        .hover;
                        margin-top: 10px;
                    }
                }
            }

            .btn-listing-crop-done {
                .hover;
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }

        &.custom-clip-cropper {
            height: 420px;
            margin-top: -210px;
            justify-content: flex-start;
            padding-top: 10px;

            .error-bar-wrapper {
                margin: 5px 0 5px 0;

                .error {
                    font-size: 9px;
                }
            }

            .custom-clip-cropper-content {
                display: flex;
                flex-direction: column;

                .custom-clip-cropper-select-col {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .dialogue {
                        .circularStdBold;
                        font-size: 10px;
                        margin-bottom: 10px;
                    }

                    .btn-select-photo {
                        .hover;
                        height: 25px;
                        font-size: 8px;
                        padding: 4px 18px;
                        margin: 0 auto 10px auto;

                        &.selected {
                            background-color: white;
                            color: @remaxRed;
                            border: 2px solid @remaxRed;

                            &:hover {
                                color: @remaxRed;
                            }
                        }
                    }

                    .custom-clip-text-input {
                        .circularStdBold;
                        width: 214px;
                        height: 25px;
                        margin-bottom: 10px;
                        background: white;
                        line-height: 25px;
                        color: @remaxBlue;
                        border: 1px solid #656565;
                        font-size: 12px;
                        padding:0 @formElPadding 0 @formElPadding;
                        box-sizing: border-box;
                        -webkit-appearance: none;

                        &::-webkit-input-placeholder {
                            color: @bgBlue;
                        }
                        &::-moz-placeholder {
                            color: @bgBlue;
                        }
                        &:-ms-input-placeholder {
                            color: @bgBlue;
                        }
                        &:-moz-placeholder {
                            color: @bgBlue;
                        }

                        &.show-error {
                            border: 1px solid #dc1c2e;
                            background-color: #fbe7e9;
                        }
                    }
                }

                .custom-clip-cropper-tool-col {
                    .flex-center-justify;
                    width: 214px;
                    height: 151px;
                    margin: auto;
                    flex-direction: column;
                    border: 3px dashed #c4c6c8;

                    &.editing {
                        border: 3px solid transparent;
                        height: auto;

                        .crop-wrapper {
                            &.adjust {
                                max-height: 151px;

                                img {
                                    max-height: 151px;
                                }
                            }
                        }
                    }
                }
            }

            .btn-custom-clip-cropper-done {
                .hover;
                position: absolute;
                bottom: 5px;
                right: 5px;
                font-size: 8px;
                padding: 4px 18px;
                height: 25px;
            }
        }

        .listing-dropzone {
            .flex-center-justify;
            width: 100%;
            height: 100%;

            p {
                .circularStdBold;
                width: 66%;
                font-size: 10px;
                color: @textGreyDarker;
                text-align: center;
            }
        }

        h2 {
            .futuraPTBold;
            font-size: 22px;
            color: @royalBlue;
            text-transform: uppercase;
            letter-spacing: 0.16em;
        }

        .rule {
            width: 57px;
            height: 1px;
            margin: 10px 0;
            .retina('../img/module_header_rule_blue@2x.png', 57px, 1px);

            &.pulse {
                animation: pulseMobile 2200ms infinite;
            }
        }

        p {
            .circularStdBook;
            max-width: 270px;
            color: @textGrey;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.04em;
        }

        .btn-back {
            background: none;
            color: @royalBlue;
            display: flex;
            align-items: center;
            margin-top: 16px;

            i {
                &.icon-back {
                    margin-right: 8px;
                    .retina('/img/icon_arrow_prev_blue@2x.png', 10px, 10px, true);
                }
            }
        }

        .btn-proceed {
            margin-top: 20px;
        }
    }

    @media @laptop {
        .content {
            width: 592px;
            height: 322.5px;
            margin-left: -296px;
            margin-top: -161.25px;

            &.browser-warning {
                a {
                    margin-top: 11px;
                    font-size: 14px;
                }
            }

            .modal-close-btn {
                top: 20px;
                right: 20px;
                width: 16px;
                height: 16px;
            }

            &.cropper {
                width: 692px;
                height: 560px;
                margin-left: -346px;
                margin-top: -280px;

                .crop-wrapper {
                    margin-top: 16px;

                    &.adjust {
                        max-height: 295px;

                        img {
                            max-height: 295px;
                        }
                    }
                }

                .btn-crop {
                    margin-top: 16px;
                }
            }

            &.listing-cropper {
                height: 543px;
                width: 875px;
                margin-top: -271.5px;
                margin-left: -437.5px;
                display: block;

                .title {
                    margin: 47px auto 0 auto;
                }

                .listing-cropper-row {
                    margin-top: 30px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-start;

                    .select-listing-pic-buttons-col {
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        align-items: unset;
                        flex-wrap: nowrap;
                        width: 306px;
                        padding: 39px 0 0 0;

                        .listing-crop-btn-wrapper {
                            margin-bottom: 50px;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;

                            .no-other-photos {
                                margin-top: 4px;
                            }

                            .btn-select-listing-photo {
                                .hover;
                                width: 202px;
                                height: 35px;
                                font-size: 15px;
                                padding: 4px 0;

                                &.disabled {
                                    background-color: #c4c6c8;
                                    transition: none;

                                    &:hover {
                                        color: white;
                                    }
                                }

                                &.active {
                                    background-color: white;
                                    color: @remaxRed;
                                    border: 2px solid @remaxRed;

                                    &:hover {
                                        color: @remaxRed;
                                    }
                                }
                            }
                        }
                    }

                    .listing-cropper-tool-col {
                        .flex-center-justify;
                        width: 427px;
                        height: 302px;
                        flex-direction: column;
                        border: 3px dashed #c4c6c8;
                        margin: 0;

                        &.editing {
                            border: 3px solid transparent;
                            height: auto;

                            .crop-wrapper {
                                &.adjust {
                                    max-height: 302px;

                                    img {
                                        max-height: 302px;
                                    }
                                }
                            }
                        }

                        .listing-cropper-dialogue {
                            .circularStdBold;
                            width: 282px;
                            margin-top: 10px;
                            font-size: 12px;
                            color: @textGreyDarker;
                            text-align: center;
                        }

                        .btn {
                            .hover;
                            margin-top: 15px;
                        }
                    }
                }

                .btn-listing-crop-done {
                    .hover;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                }
            }

            &.custom-clip-cropper {
                width: 875px;
                height: 427px;
                margin-top: -213.5px;
                margin-left: -437.5px;
                padding-top: 45px;

                .error-bar-wrapper {
                    .error {
                        font-size: 11px;
                    }
                }

                .custom-clip-cropper-content {
                    display: flex;
                    flex-direction: row;
                    margin-top: 30px;

                    .custom-clip-cropper-select-col {
                        margin-right: 43px;

                        .dialogue {
                            width: 263px;
                            font-size: 13px;
                            margin-bottom: 27px;
                        }

                        .btn-select-photo {
                            width: 197px;
                            font-size: 15px;
                            height: 35px;
                            padding: 6px 0;
                            margin-bottom: 27px;
                        }

                        .custom-clip-text-input {
                            .circularStdBold;
                            width: 263px;
                            height: 39px;
                            margin-bottom: 10px;
                            background: white;
                            line-height: 39px;
                            color: @remaxBlue;
                            border: 1px solid #656565;
                            font-size: 15px;
                            padding: 0 @formElPadding 0 @formElPadding;
                            box-sizing: border-box;
                            -webkit-appearance: none;

                            &::-webkit-input-placeholder {
                                color: @bgBlue;
                            }

                            &::-moz-placeholder {
                                color: @bgBlue;
                            }

                            &:-ms-input-placeholder {
                                color: @bgBlue;
                            }

                            &:-moz-placeholder {
                                color: @bgBlue;
                            }
                        }
                    }

                    .custom-clip-cropper-tool-col {
                        .flex-center-justify;
                        width: 452px;
                        height: 243px;
                        margin: auto;
                        flex-direction: column;
                        border: 3px dashed #c4c6c8;

                        &.editing {
                            border: 3px solid transparent;
                            height: auto;

                            .crop-wrapper {
                                &.adjust {
                                    max-height: 243px;

                                    img {
                                        max-height: 243px;
                                    }
                                }
                            }
                        }
                    }
                }

                .btn-custom-clip-cropper-done {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    width: 88px;
                    height: 35px;
                    font-size: 15px;
                    padding: 6px 0;
                }
            }

            .listing-dropzone {
                p {
                    width: 175px;
                    font-size: 12px;
                }
            }

            h2 {
                .futuraPTBold;
                max-width: 470px;
                font-size: 28px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: .16em;
            }

            .rule {
                margin: 20px 0;
                height: 2px;
                .retina('../img/module_header_rule_blue@2x.png', 57px, 2px);

                &.pulse {
                    animation: pulseLaptop 2200ms infinite;
                }
            }

            p {
                .circularStdBold;
                max-width: 471px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: .025em;
            }

            .btn-back {
                font-size: 16px;
                margin-top: 16px;

                i {
                    &.icon-back {
                        .retina('/img/icon_arrow_prev_blue@2x.png', 12.5px, 12.5px, true);
                    }
                }
            }

            .btn-proceed {
                margin-top: 40px;
            }
        }
    }

    @media @desktop {
        .content {
            width: 888px;
            height: 483px;
            margin-left: -444px;
            margin-top: -241.5px;

            &.browser-warning {
                a {
                    margin-top: 15px;
                    font-size: 22px;
                }
            }

            .modal-close-btn {
                width: 20px;
                height: 20px;
            }

            &.cropper {
                width: 988px;
                height: 820px;
                margin-left: -494px;
                margin-top: -410px;

                .crop-wrapper {
                    margin-top: 16px;

                    &.adjust {
                        max-height: 428px;

                        img {
                            max-height: 428px;
                        }
                    }
                }
            }

            &.listing-cropper {
                height: 801px;
                width: 1312.5px;
                margin-top: -400.5px;
                margin-left: -656.25px;

                .title {
                    margin: 71px auto 0 auto;
                }

                .listing-cropper-row {
                    margin-top: 45px;

                    .select-listing-pic-buttons-col {
                        width: 459px;
                        padding-top: 59px;

                        .listing-crop-btn-wrapper {
                            margin-bottom: 75px;

                            .btn-select-listing-photo {
                                width: 303px;
                                height: 53px;
                                font-size: 22px;
                                padding: 7px 0;
                            }

                            .thumbnail {
                                width: 75px;
                                height: 75px;
                                margin-left: 29px;

                                .remove-ico {
                                    top: -8px;
                                    right: -8px;
                                    width: 21px;
                                    height: 21px;
                                    background-image: url("../../img/remove-thumb-icon.svg");
                                    background-size: 13px 13px;
                                    background-position: center center;
                                    background-repeat: no-repeat;
                                    border: 3px solid @remaxBlue;
                                }
                            }
                        }
                    }

                    .listing-cropper-tool-col {
                        width: 640.5px;
                        height: 453px;

                        &.editing {
                            .crop-wrapper {
                                &.adjust {
                                    max-height: 453px;

                                    img {
                                        max-height: 453px;
                                    }
                                }
                            }
                        }

                        .listing-cropper-dialogue {
                            margin-top: 15px;
                            font-size: 18px;
                            width: 423px;
                        }

                        .btn {
                            margin-top: 23px;
                        }
                    }
                }

                .btn-listing-crop-done {
                    bottom: 30px;
                    right: 30px;
                    width: 132px;
                    height: 53px;
                    font-size: 23px;
                    padding: 9px 0;
                }
            }

            &.custom-clip-cropper {
                width: 1313px;
                height: 641px;
                margin-left: -656.5px;
                margin-top: -320.5px;
                padding-top: 68px;

                .error-bar-wrapper {
                    .error {
                        font-size: 14px;
                    }
                }

                .custom-clip-cropper-content {
                    margin-top: 45px;

                    .custom-clip-cropper-select-col {
                        margin-right: 65px;

                        .dialogue {
                            width: 395px;
                            font-size: 20px;
                            margin-bottom: 41px;
                        }

                        .btn-select-photo {
                            width: 296px;
                            font-size: 23px;
                            height: 53px;
                            padding: 9px 0;
                            margin-bottom: 41px;
                        }

                        .custom-clip-text-input {
                            width: 395px;
                            height: 59px;
                            margin-bottom: 15px;
                            line-height: 59px;
                            font-size: 23px;
                        }
                    }

                    .custom-clip-cropper-tool-col {
                        width: 678px;
                        height: 365px;

                        &.editing {
                            .crop-wrapper {
                                &.adjust {
                                    max-height: 365px;

                                    img {
                                        max-height: 365px;
                                    }
                                }
                            }
                        }
                    }
                }

                .btn-custom-clip-cropper-done {
                    bottom: 30px;
                    right: 30px;
                    width: 132px;
                    height: 53px;
                    font-size: 23px;
                    padding: 9px 0;
                }
            }

            .listing-dropzone {
                p {
                    width: 260px;
                    font-size: 18px;
                }
            }

            h2 {
                max-width: 706px;
                font-size: 42px;
            }

            .rule {
                margin: 30px 0;

                &.pulse {
                    animation: pulseDesktop 2200ms infinite;
                }
            }

            p {
                max-width: 676px;
                font-size: 22px;
                line-height: 32px;
                letter-spacing:0;
            }

            .btn-back {
                font-size: 22px;
                margin-top: 50px;

                i {
                    &.icon-back {
                        .retina('/img/icon_arrow_prev_blue@2x.png', 20px, 20px, true);
                    }
                }
            }

            .btn-proceed {
                margin-top: 60px;
            }
        }
    }
}

@keyframes pulseMobile {
    0%   { transform: scaleX(1); }
    99%  { transform: scaleX(4); }
}

@keyframes pulseLaptop {
    0%   { transform: scaleX(1); }
    99%  { transform: scaleX(8); }
}

@keyframes pulseDesktop {
    0%   { transform: scaleX(1); }
    99%  { transform: scaleX(12); }
}
