
.no-photo, .no-other-photos {
	
    color:@textGrey;
    .circularStdBook;
    font-size:10px;
    margin-top:15px;
    margin-bottom:30px;

    .checkbox {
        vertical-align: top;
        display: inline-block;
        width:16px;
        height:16px;
        cursor: pointer;
        position: relative;
        margin-right:10px;

        .bg, .checkbox-bg {
            .layer;
            border:1px solid @textGrey;
            box-sizing: border-box;
        }

        .check {
            display: none;
            .retina('../img/check_mobile@2x.png', 20px, 14px, true);
            position: absolute;
            bottom:2px;
            left:2px;

            &.checked{
                display: block;
            }
        }
    }

	@media @laptop{
	    .circularStdBook;
	    padding-top: 3px;
	}
	@media @desktop{
	    margin-top: 20px;
	    font-size: 15px;
	    padding-top: none;

	    .checkbox {
	        width: 22px;
	        height: 22px;

	        .check {
	            .retina('../img/check_mobile@2x.png', 30px, 21px, true);
	        }
	    }
	}
}

.no-other-photos {
    margin: 5px auto;

    @media @laptop {
        margin: 0;
        font-size: 12px;
    }

    @media @desktop {
        font-size: 14px;
    }
}
