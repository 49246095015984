.iam-checkbox {
    width: 23px;
    height: 23px;
    min-width: 23px;
    cursor: pointer;
    position: relative;
    margin-right: 10px;

    .bg, .checkbox-bg {
        .layer;
        border: 1px solid @textGrey;
        box-sizing: border-box;
    }

    .check {
        display: none;
        position: absolute;
        top: -6px;
        right: -10px;
        .retina('../img/iam_checkmark.png', 30px, 24px, true);

        &.checked {
            display: block;
        }

        &.dark {
            .retina('../img/iam_checkmark_dark.png', 30px, 24px, true);
        }
    }

    @media @desktop {
        width: 35px;
        height: 35px;
        margin-right: 13px;

        .check {
            top: -9px;
            right: -13px;
            .retina('../img/iam_checkmark.png', 42px, 36px, true);

            &.dark {
                .retina('../img/iam_checkmark_dark.png', 42px, 36px, true);
            }
        }
    }
}

.iam-msg-select {
    .contact-info;
    .flex-center-justify;
    flex-direction: column;

    .flex-wrapper {
        width: 100%;
    }

    .community-info {
        .circularStdBold;
        color: @textGrey;
        margin: 0 auto 15px auto;
        width: 100%;
        max-width: 385px;
        font-size: 14px;
        text-align: center;
    }

    .msg-checkbox-span {
        display: flex;
        align-items: center;
        height: 35px;

        .msg-span {
            .circularStdBold;
            color: @bgDarkBlue;
            font-size: 12px;
        }
    }

    input[type=text] {
        color: @bgDarkBlue;
        margin-bottom: 28px;
        font-size: 11px;
        border: 1px solid @textGrey;
    }

    .msg-select-cta {
        width: 300px;
        .circularStdBold;
        color: @textGrey;
        font-size: 13px;
        text-align: center;
        margin: 0 auto 33px auto;
    }

    @media @laptop {
        .community-info {
            .circularStdBold;
            color: @textGrey;
            margin: 0 auto 43px auto;
            width: 540px;
            max-width: 540px;
            font-size: 14px;
            text-align: center;
        }

        .col {
            &:first-child {
                height: 296px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
            }
        }

        .msg-checkbox-span {
            height: 42px;

            .msg-span {
                font-size: 14px;
            }
        }

        input[type=text] {
            margin-bottom: 0;
        }

        .msg-select-cta {
            width: 400px;
            .circularStdBold;
            color: @textGrey;
            font-size: 13px;
            text-align: center;
            margin: 33px auto 33px auto;
        }

        .btn-iam-create {
            .hover;
            width: 211px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            box-sizing: content-box;
            padding: 0;

            &:hover {
                padding: 0;
                box-sizing: content-box;
            }
        }
    }

    @media @desktop {
        .community-info {
            margin: 0 auto 65px auto;
            width: 810px;
            max-width: 810px;
            font-size: 22px;
        }

        .col {
            &:first-child {
                height: 397px;
            }
        }

        .msg-checkbox-span {
            height: 66px;

            .msg-span {
                font-size: 22px;
            }
        }

        input[type=text] {
            margin-bottom: 0;
        }

        .msg-select-cta {
            width: 600px;
            font-size: 20px;
            margin: 50px auto 50px auto;
        }

        .btn-iam-create {
            width: 317px;
            height: 60px;
            line-height: 60px;
            font-size: 24px;
        }
    }
}
