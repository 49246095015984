#iam-agent-info-preview{
    position: absolute;
    transform-origin: top left;
    background: url("https://d1haqt8qautnl4.cloudfront.net/iam_img/prominence-bg.jpg") 100% 100% no-repeat;
    .content-wrapper{
        width:982px;
        height:100%;
        position:absolute;
        top: 0;
        left: 827px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#ffffff;
    }
    
    .content-wrapper.no-image{
        left:469px;
    }

    .logo-wrapper{
        width:400px;
        height:285px;
        display: flex;
        align-items: center;    
        justify-content: center;
        margin-top:72px;
    }

    .logo-wrapper .remax_collection{
        margin-top:65px;
    }

    .logo-wrapper .remax_commercial{
        margin-top: 75px;
    }

    #content-wrapper-standard .name{
        text-wrap: nowrap;
        font-family: 'Gotham-Medium';
        margin-top: 57px;
        font-size: 161px;
        height: 180px;
        line-height: 180px;
    }

    #content-wrapper-standard .agent-info{
        text-wrap: nowrap;
        font-family: 'Gotham-Book';
        font-size: 44px;
        margin-top: 4px;
        line-height: 53px;
        height: 53px;
        margin-bottom: 84px;
    }

    #content-wrapper-standard .contact-info{
        text-wrap: nowrap;
        font-family: 'Gotham-Medium';
        font-size: 46px;
        margin-top: 40px;
        margin-bottom: 39px;
        height:55px;
        line-height: 55px;
    }

    #content-wrapper-prominence .logo-wrapper{
        margin-bottom:60px;
    }

    #content-wrapper-prominence .prominence-agent-info{
        text-wrap: nowrap;
        font-family: 'Gotham-Book';
        font-size: 60px;
        margin-top: 43px;
        line-height: 72px;
        height: 72px;
    }

    #content-wrapper-prominence .contact-info{
        text-wrap: nowrap;
        font-family: 'Gotham-Medium';
        font-size: 46px;
        margin-top: 25px;
        margin-bottom: 32px;
        height: 55px;
        line-height: 55px;
    }

    #content-wrapper-prominence .office-name{
        text-wrap: nowrap;
        font-family: 'Gotham-Medium';
        font-size: 70px;
        margin-top: 52px;
        height: 75px;
        line-height: 75px;
    }

    #content-wrapper-prominence .office-phone{
        text-wrap: nowrap;
        font-family: 'Gotham-Medium';  
        font-size: 70px;
        margin-top: 10px;
        height: 75px;
        line-height: 75px;
    }

    #legal{
        font-family:'Gotham-Book-Italic';
        position: absolute;
        width:100%;
        bottom:63px;
        left:0px;
        text-align: center;
        color:#e4ebee;
        opacity:0.6;
        font-size: 19px;
        letter-spacing: 1px;
        word-spacing: 1px;
    }

    .hrule{
        width:100%;
        height:3px;
        background-color: white;
    }

    #headshot-wrapper{
        position: absolute;
        width: 600px;
        height: 685px;
        top: 182px;
        left: 136px;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            max-width:100%;
            max-height:100%;
        }
    }

    #content-wrapper-prominence.no-image{
        width:1264px;
        left:328px;
    }
}